import { Modal } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateModalInfo } from '../../../redux/slices/helpers';
import { InfoLink } from '../../atoms/Link/Link';
import { Wrapper, Title, Subtitle, TooltipWrapper } from './styles';

export type PageIntroductionProps = {
  title: string;
  subtitle?: string | React.ReactNode | React.ReactNode[];
  titleTestId: string;
  subtitleTestId?: string;
  includeWrapper?: boolean;
  modalTitle?: string;
  children?: React.ReactNode | React.ReactNode[];
  applyDecibelClass?: boolean;
};

export default function PageIntroduction({
  title,
  subtitle,
  titleTestId,
  subtitleTestId,
  includeWrapper = true,
  modalTitle = '',
  children,
  applyDecibelClass,
}: PageIntroductionProps): React.JSX.Element {
  const dispatch = useDispatch();
  const [tooltipModal, setTooltipModal] = useState(false);
  const component = (
    <>
      <Title
        className={applyDecibelClass ? 'axa-mask' : ''}
        variant="h1"
        data-testid={titleTestId}
        $subtitleExists={!!subtitle}
      >
        {title}
      </Title>
      {subtitle && (
        <Subtitle variant="h3" data-testid={subtitleTestId}>
          {subtitle}
        </Subtitle>
      )}
      {children && (
        <>
          <TooltipWrapper>
            <InfoLink
              testId="tooltip-link"
              link={modalTitle}
              onClickHandler={() => {
                setTooltipModal(!tooltipModal);
                dispatch(updateModalInfo({ isModalOpen: true, modalTitle }));
              }}
            />
          </TooltipWrapper>
          <Modal
            variant="info"
            title={modalTitle}
            open={tooltipModal}
            onClose={() => {
              setTooltipModal(!tooltipModal);
              dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
            }}
            dataTestid="tooltip-modal"
          >
            <Box>{children}</Box>
          </Modal>
        </>
      )}
    </>
  );

  return includeWrapper ? <Wrapper>{component}</Wrapper> : component;
}
