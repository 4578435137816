import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const CoreNetworkCardContent = styled(Box)`
  justify-content: column;
  text-align: left;
  width: 100%;
`;

export const NetworkModalContent = styled(Box)`
  padding: 0px !important;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }

  ${theme.breakpoints.up('md')} {
    > *:not(:last-child) {
      margin-bottom: 0px;
    }
  }
`;
