import styled from 'styled-components';
import { theme } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import { ReactComponent as YellowBlock } from '../../../assets/svg/yellow_block.svg';
import { ReactComponent as RedBlock } from '../../../assets/svg/red_block.svg';
import { ReactComponent as GreenBlock } from '../../../assets/svg/green_block.svg';
import { ReactComponent as BlueBlock } from '../../../assets/svg/blue_block.svg';
import { ReactComponent as BuildingBlocks } from '../../../assets/svg/entitlements_building_blocks.svg';

const blockImageStyle = `
  ${theme.breakpoints.down('md')} {
    width: 24px;
    height: 24px;
  }
`;

export const BuildingBlocksImage = styled(BuildingBlocks)`
  margin-bottom: 32px;
  ${theme.breakpoints.down('md')} {
    width: 254px;
    height: 195.014px;
    margin-bottom: 24px;
  }
`;

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 0px;
  }
`;

export const EntitlementsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
    gap: 32px;
  }
`;

export const EntitlementsBlockContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const YellowBlockImage = styled(YellowBlock)`
  ${blockImageStyle}
`;

export const GreenBlockImage = styled(GreenBlock)`
  ${blockImageStyle}
`;

export const RedBlockImage = styled(RedBlock)`
  ${blockImageStyle}
`;

export const BlueBlockImage = styled(BlueBlock)`
  ${blockImageStyle}
`;
