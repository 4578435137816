import { Text, H4, Modal, H3, ContactCard, colors } from '@digitalportal-ui/core';
import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { ReactComponent as RoundelIcon } from '../../../assets/svg/icon_roundels.svg';
import { ConfirmationModalContent, InfoModalContent } from './ModalsStyles';
import configData from '../../../config/config.json';
import { makePhoneCall } from '../../../lib/utils/phoneCall';
import { ReactComponent as Phone } from '../../../assets/svg/phone.svg';
import { TStore } from '../../../redux/store';

type DefaultModalProps = {
  open: boolean;
  modalTitle: string;
  onClose: () => void;
}

interface ConfirmationModalProps extends DefaultModalProps {
  onSave: (success: boolean, taskAlreadyExists: boolean) => void;
  onError: () => void;
  modalData: {
    title?: string;
    firstname: string;
    lastname: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postcode: string;
    quoteNumber: string;
    quoteKey: string;
  };
}

interface InfoModalProps extends DefaultModalProps {
  text: string;
  onSave: () => void;
}

interface PreExistingConditionsModalProps extends DefaultModalProps {
  onSave: () => void;
}

interface ErrorModalProps extends DefaultModalProps {
  quoteNumber: string;
  phoneNumber: string;
}

export const ConfirmationModal = ({
  open,
  modalTitle,
  onClose,
  onSave,
  onError,
  modalData: { title, firstname, lastname, addressLine1, addressLine2, city, postcode, quoteNumber, quoteKey },
}: ConfirmationModalProps) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      variant="confirmation"
      title={modalTitle}
      saveButtonEnabled={!loading}
      open={open}
      onClose={onClose}
      onSave={async () => {
        try {
          setLoading(true);
          const response = await axios.post(
            configData.SENDPOSTALQUOTE_URL,
            { quoteNumber, quoteKey },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          );
          setLoading(false);
          onSave(response.data.success, response.data.taskAlreadyExists);
        } catch (error) {
          setLoading(false);
          onError();
        }
      }}
      dataTestid="postal-error-modal"
    >
      <ConfirmationModalContent>
        <Text data-testid="postal-modal-request" semibold>
          Please note postal quotes can take between 7 and 10 working days to arrive
        </Text>
        <H4 data-testid="postal-modal-info" bold>
          We will send a copy of your quote to your address, please confirm we have the correct details below.
        </H4>
        <Text data-testid="postal-modal-user-name">{`${title ?? ''} ${firstname} ${lastname}`}</Text>
        <Text data-testid="postal-modal-addressline1">{addressLine1}</Text>
        {addressLine2 && <Text data-testid="postal-modal-addressline2">{addressLine2}</Text>}
        <Text data-testid="postal-modal-city">{city}</Text>
        <Text data-testid="postal-modal-postcode">{postcode}</Text>
      </ConfirmationModalContent>
    </Modal>
  );
};

export const InfoModal = ({ open, modalTitle, text, onClose, onSave }: InfoModalProps) => (
  <Modal variant="info" title={modalTitle} open={open} onClose={onClose} onSave={onSave} dataTestid="info-modal">
    <InfoModalContent>
      <RoundelIcon />
      <H3>{text}</H3>
    </InfoModalContent>
  </Modal>
);

export const PreExisitingConditionsModal = ({ open, modalTitle, onClose, onSave }: PreExistingConditionsModalProps) => (
  <Modal
    dataTestid="Pre-Existing-Conditions-Modal"
    variant="confirmation"
    open={open}
    title={modalTitle}
    onClose={onClose}
    onSave={onSave}
    customCloseButtonText="Go back"
    customSaveButtonText="Yes, continue"
    showCloseFab
  >
    <Box>
      <Text mb={1}>
        A pre-existing condition is any disease, illness or injury that you&apos;ve had medication,
        advice or treatment for or symptoms of, in the last three years before your cover starts.
        It doesn&apos;t matter if your condition has been diagnosed or not.
      </Text>
      <Text semibold>Are you happy to go ahead?</Text>
    </Box>
  </Modal>
);

export const PostalErrorModal = ({ open, modalTitle, onClose, quoteNumber, phoneNumber }: ErrorModalProps) => {
  const { availability } = useSelector((state: TStore) => state.helpersState.helpers);

  return (
    <Modal variant="info" title={modalTitle} open={open} onClose={onClose} dataTestid="error-modal">
      <ContactCard
        icon={<Phone />}
        header="Something went wrong, it looks like we’re having problems with this service right now."
        subHeader={`If you would like to receive a postal copy of your quote, please call our support team using your quote no. ${quoteNumber} as a reference.`}
        buttonText={`Call ${phoneNumber}`}
        title="Call us"
        bgColor={colors.lightBlue}
        availabilityStatus={availability.workTimeText}
        avatarBgColor={availability.availabilityStatus ? colors.white : colors.mercury}
        buttonWidthFitContent
        isButtonDisabled={!availability.availabilityStatus}
        onButtonClick={() => makePhoneCall(phoneNumber)}
        testId="PostalQuoteContactCard"
      />
    </Modal>
);
};
