import { H1, H3 } from '@digitalportal-ui/core';
import Header from '../Header/Header';
import { ContainerWrapper, FallbackImageWrapper, FallbackWrapper, TitleWrapper } from './styles';
import { ReactComponent as FallbackImage } from '../../../assets/svg/cyclist.svg';

type FallbackProps = {
  header: string;
  subHeader: string;
};

export default function Fallback({ header, subHeader }: FallbackProps): React.JSX.Element {
  return (
    <>
      <Header />
      <FallbackWrapper role="main">
        <ContainerWrapper>
          <TitleWrapper>
            <H1 sx={{
              marginBottom: {
                xs: '16px',
                md: '32px',
              },
            }}
            >
              {header}
            </H1>
            <H3 sx={{
              marginBottom: {
                xs: '16px',
              },
            }}
            >
              {subHeader}
            </H3>
          </TitleWrapper>
          <FallbackImageWrapper>
            <FallbackImage />
          </FallbackImageWrapper>
        </ContainerWrapper>
      </FallbackWrapper>
    </>
  );
}
