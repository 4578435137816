import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ContactCard, colors } from '@digitalportal-ui/core';

import { useEffect, useState } from 'react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { TStore } from '../../../redux/store';
import { Wrapper, Heading, ContentWrapper } from './styles';
import Routes from '../../../enums/routes';
import Header from '../../templates/Header/Header';
import { FooterWithStaticLinks } from '../../templates/Footer/Footer';
import { Main } from '../../templates/styles';
import { ReactComponent as Phone } from '../../../assets/svg/phone.svg';
import { makePhoneCall } from '../../../lib/utils/phoneCall';
import { appInsights } from '../../../lib/utils/services/applicationInsightsService';

export enum UnHappyPathVariant {
  TryAgain = 'TryAgain',
  PhoneCall = 'PhoneCall',
}

export type UnhappyPathProps = {
  title?: string;
  body?: string;
  route?: Routes;
  fromRoute?: Routes;
  quoteNumber?: string;
  description?: string;
  variant?: UnHappyPathVariant;
};

export default function UnhappyPath(): React.JSX.Element {
  const history = useNavigate();
  const location = useLocation();
  const [logged, setLogged] = useState(false);
  const [isPhoneCallVariant, setIsPhoneCallVariant] = useState(false);
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const availability = useSelector((state: TStore) => state.helpersState.helpers.availability);
  const phoneNumber = useSelector((state: TStore) => state.helpersState.helpers.salesPhoneNumber);

  const props = location.state as UnhappyPathProps;

  useEffect(() => {
    setIsPhoneCallVariant(props?.variant === UnHappyPathVariant.PhoneCall);
    if (!logged) {
      appInsights.trackEvent({ name: 'Unhappy path displayed' });
      if (props?.variant === UnHappyPathVariant.PhoneCall) {
        appInsights.trackTrace({
          message: 'Offline redirect error page for quote retrieval',
          severityLevel: SeverityLevel.Warning,
        });
      } else {
        appInsights.trackTrace({
          message: `Unhappy path title - ${props?.title}. Came from route - ${props?.fromRoute}`,
          severityLevel: SeverityLevel.Warning,
        });
      }
      setLogged(true);
    }
  }, [props]);

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <Wrapper display="flex" flexDirection="column" alignItems="center">
          <Heading data-testid="title">
            {isPhoneCallVariant ?
              'Sorry, we have been unable to retrieve your quote due to technical issues. Please call us and one of our agents will be able to help.' :
              'Something went wrong'
            }
          </Heading>
          <ContentWrapper>
            <ContactCard
              icon={<Phone />}
              header={props?.title}
              subHeader={props?.body}
              description={props?.description}
              buttonText={isPhoneCallVariant ? `Call ${phoneNumber}` : 'Try again'}
              secondaryButtonText={!isPhoneCallVariant ? `Call ${phoneNumber}` : ''}
              avatarBgColor={availability.availabilityStatus ? colors.pacificBlue10 : colors.mercury}
              statusTextColor={availability.workTimeColor}
              availabilityStatus={availability.workTimeText}
              title="Call us"
              isButtonDisabled={false}
              onButtonClick={() => {
                if (isPhoneCallVariant) {
                  makePhoneCall(phoneNumber);
                } else if (props.route) {
                  history(props.route, navigateOptions);
                }
              }}
              onSecondaryButtonClick={() => makePhoneCall(phoneNumber)}
              testId="UnhappyPathContactcard"
              buttonWidthFitContent
            />
          </ContentWrapper>
        </Wrapper>
      </Main>
      <FooterWithStaticLinks />
    </>
  );
}
