import styled from 'styled-components';
import { Box, List } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';
import { Main } from '../../templates/styles';
import { ReactComponent as EntitlementIm } from '../../../assets/svg/entitlement_selection_image.svg';

export const StyledMain = styled(Main)`
  overflow-x: hidden;
  margin-bottom: 0;
`;

export const PageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 150px;
`;

export const PageIntroductionWrapper = styled(Box)`
  margin-bottom: 9px;

  ${theme.breakpoints.up('md')} {
    width: 570px;
    margin-bottom: 26px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 615px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
  }
`;

export const ContentWrapperRefEmail = styled(Box)`
  padding: 16px;
  margin-bottom: 16px;
  background: ${colors.white};
  text-align: center;
  border-radius: 6px;

  ${theme.breakpoints.only('md')} {
    p {
      padding: 0px 19px;
    }
  }

  ${theme.breakpoints.up('md')} {
    width: 570px;
    margin-bottom: 32px;
    padding: 32px 13px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 610px;
    padding: 32px 33px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 730px;
    padding: 32px;
  }
`;

export const RefNrWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  margin-bottom: 16px;
  flex-wrap: wrap;

  ${theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

export const ContentWrapperSteps = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 16px;
  text-align: left;
  margin-bottom: 16px;
  background-color: ${colors.oceanBlue};
  color: ${colors.white};
  align-items: center;
  border-radius: 6px;

  h2 {
    margin-bottom: 8px;
  }

  ${theme.breakpoints.up('md')} {
    width: 690px;
    margin-bottom: 32px;
    padding: 32px;
    flex-direction: row;
  }
  ${theme.breakpoints.up('lg')} {
    width: 930px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1110px;
  }
`;

export const StepsDescriptionWrapper = styled(Box)`
  margin-bottom: 16px;

  ${theme.breakpoints.up('md')} {
    width: 338px;
    margin-bottom: 0;
  }
  ${theme.breakpoints.up('lg')} {
    width: 510px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 690px;
  }
`;

export const ArticleIntroductionWrapper = styled(Box)`
  h2 {
    margin-bottom: 29px;
  }

  p {
    margin-bottom: 16px;
  }

  ${theme.breakpoints.up('md')} {
    width: 690px;

    h2 {
      margin-bottom: 32px;
    }

    p:last-child {
      margin-bottom: 32px;
    }
  }
  ${theme.breakpoints.up('lg')} {
    width: 930px;
  }
  ${theme.breakpoints.up('xl')} {
    width: 1110px;
  }
`;

export const ImageWrapper = styled(Box)`
  display: flex;
  justify-content: center;

  ${theme.breakpoints.only('md')} {
    width: 287px;
  }
`;

export const EntitlementImage = styled(EntitlementIm)`
  max-width: 100%;
`;

export const StepsDescription = styled(Box)`
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }
`;

export const StyledList = styled(List)`
  color: white;
  padding-left: 6px;

  li {
    .MuiListItemIcon-root {
      display: flex;
      align-self: flex-start;
      margin-right: 3px;
      color: white;
    }
    
   .MuiListItemText-root {
      margin: 0;

      .MuiListItemText-primary {
        color: white;
      }
    }
  }

  .MuiListItem-root {
      padding: 0px;
      margin: 0px;
  }

  .MuiListItemIcon-root {
    min-width: 0px;
  }
}`;
