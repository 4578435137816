import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { TStore } from '../redux/store';

function FirstPaymentDateCard(coverStartDate: string, preferredDay: number) {
  const coverStartDateDayJs = dayjs(coverStartDate);
  const today = dayjs();
  const dateForward = today.add(1, 'day');
  const preferredDatePlusOneMonth = coverStartDateDayJs.add(1, 'month').set('date', preferredDay);
  if (preferredDay < coverStartDateDayJs.date()) {
    return preferredDatePlusOneMonth.format('Do MMM YYYY');
  }
  if (today.date() === preferredDay) {
    return dateForward.format('Do MMM YYYY');
  }
  return coverStartDateDayJs.set('date', preferredDay).format('Do MMM YYYY');
}

function FirstPaymentDateDD(coverStartDate: string, preferredDay: number) {
  const coverStartDateDayJs = dayjs(coverStartDate);
  const dateForward = coverStartDateDayJs.add(14, 'days');
  const prefDateThisMonth = coverStartDateDayJs.set('date', preferredDay);
  const preferredDatePlusOneMonth = coverStartDateDayJs.add(1, 'month').set('date', preferredDay);
  if (preferredDay < coverStartDateDayJs.date()) {
    return preferredDatePlusOneMonth.isBefore(dateForward, 'days')
      ? dateForward.format('Do MMM YYYY')
      : preferredDatePlusOneMonth.format('Do MMM YYYY');
  }
  if (prefDateThisMonth.isBefore(dateForward, 'days')) {
    return dateForward.format('Do MMM YYYY');
  }
  return prefDateThisMonth.format('Do MMM YYYY');
}

export function useGetPaymentDates(regularPaymentDate?: string) {
  const coverStartDate = useSelector((state: TStore) => state.quoteState.quote.coverStartDate);
  const paymentMethod = useSelector((state: TStore) => state.policyState.policy.selectedPaymentMethod);
  const isCard = paymentMethod === 'Card';
  const preferredDay =
    regularPaymentDate && regularPaymentDate?.length > 0
      ? parseInt(regularPaymentDate, 10)
      : dayjs(coverStartDate).date();

  function SubsequentPaymentDay() {
    if (regularPaymentDate) {
      return dayjs().date(parseInt(regularPaymentDate, 10)).format('Do').toString();
    }
    const coverStartDateDayJs = dayjs(coverStartDate).subtract(1, 'months');
    return coverStartDateDayJs.set('date', preferredDay).format('Do').toString();
  }

  const FirstPaymentDate = () => (
    isCard ?
      FirstPaymentDateCard(coverStartDate, preferredDay) :
      FirstPaymentDateDD(coverStartDate, preferredDay)
  );

  return { FirstPaymentDate, SubsequentPaymentDay };
}
