import { RefObject, useCallback, useRef } from 'react';
import { H3 } from '@digitalportal-ui/core';
import { Box } from '@mui/material';
import TextInputFields, { Label } from '../../../molecules/TextInputField/TextInputField';
import {
  HeadingContainer,
  SmallInput,
  SortCodeContainer,
  CheckIcon,
  CloseIcon,
  StyledListItem,
  StyledListItemIcon,
} from './styles';

type BankAccountDetailsProps = {
  valid: boolean | null;
  validationMessage?: string;
  tabIndex?: number;
};

const sortCodeMask = /^\d*$/;

export default function BankAccountDetails({ valid, validationMessage, tabIndex = 0 }: BankAccountDetailsProps): React.JSX.Element {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputs: RefObject<HTMLInputElement>[] = [useRef(null), useRef(null), useRef(null)];

  const onFocus = useCallback(
    ({ nativeEvent: { target } }: React.FocusEvent) => {
      const listener = (event: Event) => {
        // eslint-disable-next-line no-shadow
        const { name, value, maxLength } = event.target as HTMLInputElement;
        if (value.length === maxLength) {
          setTimeout(() => {
            const elementIndex = inputs.findIndex(({ current }) => current?.name === name);
            const nextElement = inputs[elementIndex + 1];
            if (nextElement?.current) {
              nextElement.current.focus();
            }
            if (value.length === maxLength && target) {
              target.removeEventListener('input', listener);
            }
          });
        }
      };
      if (target) {
        target.addEventListener('input', listener);
      }
    },
    [inputs],
  );

  return (
    <Box>
      <HeadingContainer>
        <H3 data-testid="bank-account-details-header" bold>Bank account details</H3>
      </HeadingContainer>
      <TextInputFields
        label="Account holder name (as shown on your account)"
        name="cardholdername"
        testId="text-input-field-cardholdername"
        InputProps={{ tabIndex }}
      />
      <Label name="sortcode1" label="Sort code" />
      <SortCodeContainer>
        <TextInputFields
          ref={inputs[0]}
          onFocus={onFocus}
          maxLength={2}
          mask={sortCodeMask}
          name="sortcode1"
          testId="text-input-field-sortcode1"
          InputProps={{ tabIndex }}
          inputmode="numeric"
        />
        <TextInputFields
          ref={inputs[1]}
          onFocus={onFocus}
          maxLength={2}
          mask={sortCodeMask}
          name="sortcode2"
          testId="text-input-field-sortcode2"
          InputProps={{ tabIndex }}
          inputmode="numeric"
        />
        <TextInputFields
          ref={inputs[2]}
          onFocus={onFocus}
          maxLength={2}
          mask={sortCodeMask}
          name="sortcode3"
          testId="text-input-field-sortcode3"
          InputProps={{ tabIndex }}
          inputmode="numeric"
        />
      </SortCodeContainer>
      <SmallInput>
        <TextInputFields
          label="Account number"
          name="bankaccountnumber"
          testId="text-input-field-bankaccountnumber"
          InputProps={{ tabIndex }}
          inputmode="numeric"
        />
      </SmallInput>
      {validationMessage && (
        <StyledListItem>
          <StyledListItemIcon>{valid ? <CheckIcon /> : <CloseIcon />}</StyledListItemIcon>
          <H3 bold data-testid="confirm-account-validation-message">
            {validationMessage}
          </H3>
        </StyledListItem>
      )}
    </Box>
  );
}
