import { Divider, Box, SxProps } from '@mui/material';
import { colors, ContactCard, H3, Text } from '@digitalportal-ui/core';
import { Formik, Form, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { SetStateAction, useState } from 'react';
import { Theme } from '@mui/system';
import BankAccountDetails from './BankAccountDetails/BankAccountDetails';
import radioFormConfig from './radioFormsConfig';
import GrayOutOverlay from './GrayOutOverlay';
import { ReactComponent as DirectDebitIcon } from '../../../assets/svg/direct_debit.svg';
import { HeadingContainer } from './styles';
import { DirectDebitSchema } from './validationSchema';
import { Checkbox } from '../../atoms';

import { updatePaymentMethodDetails, updatePaymentMethodType } from '../../../redux/slices/policy/policy.slice';
import { Availability, ValidateBankDetailsType } from '../../../redux/slices/helpers/helpers.types';
import { TStore } from '../../../redux/store';
import { ContentSection, RadioContainer } from '../PaymentDetailsForm/styles';
import RadioBlock from '../../molecules/RadioBlock/RadioBlock';
import RadioVariants from '../../../enums/radioVariants';
import { ErrorFocusOnNext } from '../../../lib/utils/errorFocusOnNext';
import ErrorMessageBox from '../../molecules/ErrorMessageBox/errorMessageBox';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { validateBankDetails } from '../../../lib/utils/services/quoteService';
import IndividualDirectDebit from '../../../assets/pdfs/IndividualDirectDebit.pdf';
import QuestionHeader from '../../atoms/QuestionHeader/QuestionHeader';
import { makePhoneCall } from '../../../lib/utils/phoneCall';
import { ReactComponent as Phone } from '../../../assets/svg/phone.svg';
import HandleError from '../../../lib/utils/handleError';

const initialFormValues = {
  ukbank: null,
  singleperson: null,
  sortcode1: '',
  sortcode2: '',
  sortcode3: '',
  cardholdername: '',
  bankaccountnumber: '',
  happyToProceed: false,
};

type DirectDebitProps = {
  formCompleted: (enable: boolean) => void;
  validating: (enable: boolean) => void;
};

type HandleErrorMessageBoxProps = {
  touched: boolean | undefined;
  value: string | number | boolean | null;
  errorClose: boolean;
  setErrorClose: (value: SetStateAction<boolean>) => void;
  style: SxProps<Theme> | undefined;
};

type DirectDebitMessageProps = {
  availability: Availability;
  phoneNumber: string;
};

function HandleErrorMessageBox({
  touched,
  value,
  errorClose,
  setErrorClose,
  style,
}: HandleErrorMessageBoxProps): React.JSX.Element | null {
  if (touched && value === null && !errorClose) {
    return (
      <Box sx={style}>
        <ErrorMessageBox closeError={setErrorClose} />
      </Box>
    );
  }
  return null;
}

function DirectDebitMessage({ availability, phoneNumber }: DirectDebitMessageProps): React.JSX.Element {
  return (
    <ContactCard
      icon={<Phone />}
      bgColor={colors.lightBlue}
      avatarBgColor={colors.white}
      header="Unfortunately, you can’t pay online if both you and another account holder need to agree to the payment."
      subHeader="We’ll need to speak to the bank account holder on this call, as well as yourself. Or you can download and print a Direct Debit form, ask the account holder to fill it in, and send it back to us. All the instructions are on the form."
      buttonText="Download Direct Debit Instruction form"
      secondaryButtonText={`Call ${phoneNumber}`}
      title="Call us"
      availabilityStatus={availability.workTimeText}
      isButtonDisabled={!availability.availabilityStatus}
      onButtonClick={() => {
          window.open(
            IndividualDirectDebit,
            '_blank',
            'noopener',
          );
      }}
      onSecondaryButtonClick={() => makePhoneCall(phoneNumber)}
      testId="PaymentContactCard"
      buttonWidthFitContent
    />
  );
}

export default function DirectDebitForm({ formCompleted, validating }: DirectDebitProps): React.JSX.Element {
  const dispatch = useDispatch();
  const [valid, setValid] = useState<null | boolean>(null);
  const [ukBankErrorClose, setUkBankErrorClose] = useState(false);
  const [singlePersonErrorClose, setSinglePersonErrorClose] = useState(false);
  const [validationMessage, setValidationMessage] = useState('');
  const availability = useSelector((state: TStore) => state.helpersState.helpers.availability);
  const phoneNumber = useSelector((state: TStore) => state.helpersState.helpers.salesPhoneNumber);

  function Submitter() {
    const { submitForm, isValid } = useFormikContext<any>();
    useCustomEventListener('pageNavigationNext', () => {
      if (isValid) {
        submitForm();
      }

      setUkBankErrorClose(false);
      setSinglePersonErrorClose(false);
    });
    return null;
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={DirectDebitSchema}
      validateOnMount
      onSubmit={async (values) => {
        validating(true);
        setValidationMessage('');
        const validateBankDetailsModel: ValidateBankDetailsType = {
          accountNumber: values.bankaccountnumber,
          sortCode: `${values.sortcode1}-${values.sortcode2}-${values.sortcode3}`,
        };
        dispatch(updatePaymentMethodType('Direct Debit'));
        validateBankDetails(validateBankDetailsModel)
          .then((result) => {
            dispatch(
              updatePaymentMethodDetails({
                directDebit: {
                  accountName: values.cardholdername,
                  accountNumber: validateBankDetailsModel.accountNumber,
                  sortCode: validateBankDetailsModel.sortCode,
                  base64Hash: result.base64Hash,
                },
                card: null,
              }),
            );
            setValid(result.isValid);
            formCompleted(result.isValid);
            setValidationMessage(
              result.isValid ? '' : 'Sorry, there’s a problem with those details. Please try entering them again.',
            );
            validating(false);
          })
          .catch((err) => {
            validating(false);
            HandleError(err, dispatch);
          });
      }}
    >
      {({ values, errors, touched }) => (
        <Form>
          <HeadingContainer>
            <H3 data-testid="direct-debit-confirmation-header" bold inline>
              Direct Debit confirmation{' '}
            </H3>
            <DirectDebitIcon />
          </HeadingContainer>
          <ContentSection>
            <QuestionHeader title={radioFormConfig.ukbankRadio.label} testId="uk-bank-question" />
            <RadioContainer sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <Box display="contents">
                <RadioBlock
                  dataTestId="uk-bank-yes"
                  isErrored={touched.ukbank && values.ukbank === null}
                  variant={RadioVariants.dot}
                  name="ukbank"
                  value={0}
                  checkedColor={colors.oceanBlue}
                >
                  <Text>Yes</Text>
                </RadioBlock>
              </Box>
              <Box display="contents">
                <RadioBlock
                  dataTestId="uk-bank-no"
                  isErrored={touched.ukbank && values.ukbank === null}
                  variant={RadioVariants.dot}
                  name="ukbank"
                  value={1}
                  checkedColor={colors.oceanBlue}
                >
                  <Text>No</Text>
                </RadioBlock>
              </Box>
            </RadioContainer>
            {values.ukbank === 1 && DirectDebitMessage({ availability, phoneNumber })}
            {HandleErrorMessageBox({
              touched: touched.ukbank,
              value: values.ukbank,
              errorClose: ukBankErrorClose,
              setErrorClose: setUkBankErrorClose,
              style: { marginBottom: { md: '16px' } },
            })}
          </ContentSection>
          <Divider />
          {GrayOutOverlay(values.ukbank === 1)}
          <ContentSection>
            <QuestionHeader title={radioFormConfig.singlePerson.label} testId="single-person-question" />
            <RadioContainer sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <Box display="contents">
                <RadioBlock
                  dataTestId="single-person-yes"
                  isErrored={touched.singleperson && values.singleperson === null}
                  variant={RadioVariants.dot}
                  name="singleperson"
                  value={0}
                  checkedColor={colors.oceanBlue}
                  tabIndex={values.ukbank === 1 ? -1 : 0}
                >
                  <Text>Yes</Text>
                </RadioBlock>
              </Box>
              <Box display="contents">
                <RadioBlock
                  dataTestId="single-person-no"
                  isErrored={touched.singleperson && values.singleperson === null}
                  variant={RadioVariants.dot}
                  name="singleperson"
                  value={1}
                  checkedColor={colors.oceanBlue}
                  tabIndex={values.ukbank === 1 ? -1 : 0}
                >
                  <Text>No</Text>
                </RadioBlock>
              </Box>
            </RadioContainer>
            {values.singleperson === 1 && values.ukbank === 0 && DirectDebitMessage({ availability, phoneNumber })}
            {HandleErrorMessageBox({
              touched: touched.singleperson,
              value: values.singleperson,
              errorClose: singlePersonErrorClose,
              setErrorClose: setSinglePersonErrorClose,
              style: { marginBottom: { md: '4px' } },
            })}
          </ContentSection>
          {GrayOutOverlay(values.singleperson === 1)}
          <Checkbox
            isErrored={touched.happyToProceed && errors.happyToProceed !== undefined}
            name="happyToProceed"
            testId="happyToProceed"
            tabIndex={values.ukbank === 1 || values.singleperson === 1 ? -1 : 0}
          >
            <Text data-testid="happy-to-proceed-text" style={{ padding: '9px 0' }}>
              I’m happy to go ahead with this Direct Debit payment
            </Text>
          </Checkbox>
          <BankAccountDetails
            valid={valid}
            validationMessage={validationMessage}
            tabIndex={values.ukbank === 1 || values.singleperson === 1 ? -1 : 0}
          />
          <ErrorFocusOnNext setToTouched={values.ukbank !== 1 && values.singleperson !== 1} />
          <Submitter />
        </Form>
      )}
    </Formik>
  );
}
