import { H4, Text } from '@digitalportal-ui/core';
import { Box } from '@mui/system';
import { CardWrapper } from './styles';

export type GenericCardProps = {
  title: string;
  testId: string;
  description?: string | React.ReactNode | React.ReactNode[];
  footerText?: string | React.ReactNode | React.ReactNode[];
  children?: React.ReactNode;
  gutter?: boolean;
};

function GenericCard({ title, testId, description, footerText, children, gutter }: GenericCardProps): React.JSX.Element {
    return (
      <CardWrapper data-testId={testId} $gutter={gutter}>
        <H4 bold sx={{ mb: { xs: '8px', md: '16px' } }} data-testid={`title-${testId}`}>
          {title}
        </H4>
        {description &&
          <Text sx={{ mb: { xs: '16px', md: '24px' } }}>
            {description}
          </Text>
        }
        {children &&
          <Box sx={{ mb: { xs: '8px', md: '16px' } }}>
            {children}
          </Box>
        }
        {footerText}
      </CardWrapper>
    );
}

export default GenericCard;
