import { H4, colors } from '@digitalportal-ui/core';
import { SyntheticEvent } from 'react';
import TagManager from 'react-gtm-module';
import { CustomAccordion, CustomAccordionSummary, CustomAccordionDetails, CustomAccordionSummaryHeading } from './styles';
import { ReactComponent as ChevronIcon } from '../../../assets/svg/chevron_bottom.svg';
import AccordionVariants from '../../../enums/accordionVariants';
import { SubTitle } from '../../atoms/AccordionSummaryDetails/styles';
import { ColorsType } from '../../../redux/slices/helpers/helpers.types';
import { InfoBubble } from '../../templates/styles';

export type AccordionProps = {
  title: string;
  subtitle?: string;
  variant?: AccordionVariants;
  children?: string | React.ReactNode;
  expanded?: boolean;
  currentlyExpanded?: boolean;
  disabled?: boolean;
  chipText?: string;
  chipColor?: ColorsType;
  onChange?: (event: SyntheticEvent<Element, Event>, nowExpanded: boolean) => void;
};

export default function StyledAccordion({
  title,
  subtitle,
  children,
  expanded,
  variant = AccordionVariants.faq,
  disabled = false,
  currentlyExpanded,
  chipText,
  chipColor,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => null,
}: AccordionProps): React.JSX.Element {
  return (
    <CustomAccordion
      $variant={variant}
      onChange={(event, nowExpanded) => {
        if (nowExpanded) {
          const tagManagerArgs = {
            dataLayer: {
              event: 'accordion',
              accordion_type: 'Arrow',
              accordion_title: title,
            },
          };
          TagManager.dataLayer(tagManagerArgs);
        }
        onChange(event, nowExpanded);
      }}
      square
      disabled={disabled}
      defaultExpanded={expanded}
      expanded={currentlyExpanded}
    >
      <CustomAccordionSummary
        $variant={variant}
        expandIcon={
          <ChevronIcon
            style={{ width: '16px', height: '16px' }}
            data-testid={`accordion-expand-icon-${title.replace(/\s+/g, '-')}`}
          />
        }
        data-testid={`accordion-summary-${title.replace(/\s+/g, '-')}`}
      >
        <CustomAccordionSummaryHeading $variant={variant}>
          <H4 bold align="left" data-testid={`accordion-title-${title.replace(/\s+/g, '-')}`}>
            {title}
          </H4>
          {subtitle && <SubTitle display="inline-block">{subtitle}</SubTitle>}
        </CustomAccordionSummaryHeading>
        {chipText && (
          <InfoBubble
            data-testid={`chip-${title.replace(/\s+/g, '-')}`}
            sx={{ margin: { md: '0 16px 0 32px' }, marginBottom: { xs: '8px' } }}
            $chipColor={chipColor ?? colors.pacificBlue10}
          >
            {chipText}
          </InfoBubble>
        )}
      </CustomAccordionSummary>
      <CustomAccordionDetails $variant={variant} data-testid={`accordion-detail-${title.replace(/\s+/g, '-')}`}>
        {children}
      </CustomAccordionDetails>
    </CustomAccordion>
  );
}
