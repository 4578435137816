import { Box } from '@mui/material';
import { Text, H2, colors } from '@digitalportal-ui/core';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import DownloadCard from '../../molecules/DownloadCard/DownloadCard';
import { Wrapper, DocumentWrapper } from './DownloadCardsStyles';
import MembershipHandbook from '../../../assets/pdfs/AXA Health Plan generic handbook.pdf';
import MembershipHandbook2024 from '../../../assets/pdfs/AXA Health Plan generic handbook October 2024.pdf';
import AboutAXAHealthAHP from '../../../assets/pdfs/About AXA Health AHP.pdf';
import { TStore } from '../../../redux/store';

export default function DownloadCards(): React.JSX.Element {
  const phoneNumber = useSelector((state: TStore) => state.helpersState.helpers.salesPhoneNumber);
  const coverStartDateValue = useSelector((state: TStore) => state.quoteState.quote.coverStartDate);

  const isBeforeOctober2024 = () => {
    const cutoffDate = dayjs('2024-10-01');
    return dayjs(coverStartDateValue).isBefore(cutoffDate);
  };

  const documentsValues = [
    {
      documentTitle: 'Membership handbook',
      subtitle: 'Tells you what you are and aren’t covered for',
      link: isBeforeOctober2024() ? MembershipHandbook : MembershipHandbook2024,
    },
    {
      documentTitle: 'Information about AXA Health',
      subtitle: 'Legal information about who we are',
      link: AboutAXAHealthAHP,
    },
  ];

  return (
    <Wrapper>
      <H2
        align="left"
        width="100%"
        sx={{ marginBottom: { sm: '16px', md: '16px' } }}
        data-testid="title-useful-documents"
      >
        Information to check
      </H2>
      <Text
        align="left"
        paddingTop="0px"
        fontWeight="600"
        line-height="20.11px"
        sx={{ marginBottom: { sm: '16px', md: '32px' } }}
      >
        Please read these documents carefully before you buy.
        <br />
        If you go ahead, we&apos;ll send you another handbook that&apos;s personalised to the cover you&apos;ve chosen.
      </Text>
      <DocumentWrapper>
        {documentsValues.map((value) => (
          <DownloadCard
            key={value.documentTitle}
            title={value.documentTitle}
            subtitle={value.subtitle}
            link={value.link}
            urlText="Download"
            fileSizeText="PDF 200 MB"
          />
        ))}
      </DocumentWrapper>
      <Text align="left" padding="0px" data-testid="description-useful-documents">
        Want a paper copy? Please call us on:{' '}
        <Box component="span" fontWeight="600" color={colors.oceanBlue300}>
          {phoneNumber}
        </Box>
        . We&apos;re here Monday to Friday 9am-6pm.
      </Text>
    </Wrapper>
  );
}
