import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Toolbar, AppBar } from '@mui/material';
import { theme } from '@digitalportal-ui/core';
import { ReactComponent as Logo } from '../../../assets/svg/axa_logo.svg';

export const LogoContainer = styled.div`
  flex-grow: 1;
  height: 100%;
`;

export const StyledLogo = styled(Logo)`
  flex-grow: 1;
  position: absolute;
  top: 0;
  ${theme.breakpoints.up('xs')} {
    margin: 15.5px 0 15.5px -2px;
    height: 40px;
  }
  ${theme.breakpoints.up('md')} {
    margin: 8.5px 0 9.5px 39px;
    height: 54px;
  }
  ${theme.breakpoints.up('lg')} {
    margin: 8.5px 0 9.5px 135px;
  }
  ${theme.breakpoints.up('xl')} {
    margin: 8.5px 0 9.5px 165px;
  }
`;

export const HeaderBar = styled(Toolbar)`
  margin: 0;
  padding: 0;
  border-bottom: solid 1px #cccccc;
  ${theme.breakpoints.up('xs')} {
    height: 72px;
  }
  ${theme.breakpoints.up('md')} {
    height: 73px;
  }
  background: white;
`;

export const PhoneContainer = styled.div`
  text-decoration: none;
  color: ${theme.palette.primary.dark};
  margin-bottom: 1px;
  height: 100%;
  margin-right: 16px;

  ${theme.breakpoints.up('md')} {
    display: block;
    margin-right: 39px;
  }
  ${theme.breakpoints.up('lg')} {
    margin-right: 135px;
  }
  ${theme.breakpoints.up('xl')} {
    margin-right: 165px;
  }
  .MuiGrid-container {
    height: 100%;
  }
`;

export const PhoneNumber = styled(Link)`
  text-decoration: none !important;
  color: ${theme.palette.primary.dark};
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  margin: 0 0;

  ${theme.breakpoints.up('md')} {
    font-size: 18px;
  }
`;

export const WorkingHours = styled.p`
  text-decoration: none;
  color: ${theme.palette.primary.dark};
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  margin: 0 0;

  span {
    font-weight: bold;
  }

  ${theme.breakpoints.up('md')} {
    font-size: 14px;
  }
`;

export const StyledAppBar = styled(AppBar)`
  box-shadow: none;
`;
