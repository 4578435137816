import { ContactCard, colors } from '@digitalportal-ui/core';
import { useSelector } from 'react-redux';
import { TStore } from '../../../redux/store';
import IndividualDirectDebit from '../../../assets/pdfs/IndividualDirectDebit.pdf';
import { makePhoneCall } from '../../../lib/utils/phoneCall';
import { ReactComponent as Phone } from '../../../assets/svg/phone.svg';

export default function UnhappyPathDetails(
  isShown: boolean,
  paymentMethod: number,
  quoteNumber: string,
  phoneNumber: string,
): React.JSX.Element | null {
  const availability = useSelector((state: TStore) => state.helpersState.helpers.availability);

  if (!isShown) {
    return null;
  }

  return (
    <ContactCard
      icon={<Phone />}
      bgColor={colors.lightBlue}
      avatarBgColor={colors.white}
      header={`Unfortunately, you can’t pay online if you’re using someone else’s ${
        paymentMethod === 1 ? 'bank account' : 'card'
      }.`}
      subHeader={
        paymentMethod === 0
          ? `We'll need to speak to the cardholder as well as yourself, please reference your quote number ${quoteNumber} so we can help you.`
          : 'We do have other options for you. Please call us and we’ll talk them through'
      }
      buttonText={paymentMethod === 1 ? 'Download Direct Debit Instruction form' : `Call ${phoneNumber}`}
      secondaryButtonText={paymentMethod === 1 ? `Call ${phoneNumber}` : ''}
      title="Call us"
      availabilityStatus={availability.workTimeText}
      isButtonDisabled={!availability.availabilityStatus}
      onButtonClick={() => {
        if (paymentMethod === 1) {
          window.open(IndividualDirectDebit, '_blank', 'noopener');
        } else {
          makePhoneCall(phoneNumber);
        }
      }}
      onSecondaryButtonClick={() => makePhoneCall(phoneNumber)}
      testId="PaymentContactCard"
      buttonWidthFitContent
    />
  );
}
