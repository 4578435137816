import styled from 'styled-components';
import { Box } from '@mui/material';
import { theme } from '@digitalportal-ui/core';

export const FormHolder = styled(Box)`
  width: 100%;
`;

export const TextInputHolder = styled(Box)<{ fullWidth?: boolean }>`
  width: 100%;

  ${theme.breakpoints.up('xs')} {
    width: ${(props) => (props.fullWidth ? '100%' : '220px')};
  }
  ${theme.breakpoints.up('md')} {
    width: ${(props) => (props.fullWidth ? '100%' : '200px')};
  }

  ${theme.breakpoints.up('xl')} {
    width: ${(props) => (props.fullWidth ? '100%' : '270px')};
  }
`;

export const DropDownInputHolder = styled(TextInputHolder)`
  ${theme.breakpoints.down('md')} {
    width: 120px;
  }
`;

export const ListItemContainer = styled(Box)`
  margin-left: 16px;
  
  ${theme.breakpoints.up('md')} {
    margin-left: 32px;
  }
`;
