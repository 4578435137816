import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Button, Text, H3, H5 } from '@digitalportal-ui/core';
import dayjs from 'dayjs';
import routes from '../../../enums/routes';
import { TStore } from '../../../redux/store';
import AddMemberDetailsCard from '../../atoms/AddMemberDetailsCard/AddMemberDetailsCard';
import AccordionSummaryDetails from '../../atoms/AccordionSummaryDetails/AccordionSummaryDetails';
import {
  AddMemberCardDetailsCardWrapper,
  ButtonWrapper,
  AnswerWrapper,
  NotificationWrapper,
  ButtonHolder,
  AddressContainer,
  ChildrenContainer,
  EntitlementHolder,
  StyledPriceTypography,
  SectionHeaderWrapper,
} from './MemberCardsStyles';
import PriceCardSmall from '../../molecules/PriceCardSmall/PriceCardSmall';
import EntitlementSummaryContent from '../../../content/entitlementSummaryContent.json';
import { CustomText, MessageBoxWrapper } from './styles';
import { Member } from '../../../redux/slices/quote';
import EntitlementModalContentJson from '../../../content/entitlementModalContent.json';
import { EntitlementModalContent, GetMemberIpidType } from '../../../redux/slices/helpers/helpers.types';
import { getMemberIpid } from '../../../lib/utils/services/quoteService';
import { MessageBoxStyled } from '../../templates/styles';
import HandleError from '../../../lib/utils/handleError';

const formatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export type MemberCardsProps = {
  paymentFrequency?: boolean;
};

type EntitlementSummaryContentType = {
  productCode: string[];
  description: string[];
};

export default function MemberCards({ paymentFrequency }: MemberCardsProps): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const initialQuoteValues = useSelector((state: TStore) => state?.quoteState?.quote);
  const [memberCardsExpanded, setMemberCardsExpanded] = useState([false]);
  const entitlementContent: EntitlementModalContent[] = EntitlementModalContentJson;

  const majorHealthQuestions = useSelector(
    (state: TStore) => state.helpersState.helpers.majorHealthQuestions.questions,
  );
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);
  const members = useSelector((state: TStore) => state.quoteState.quote.members);
  const { navigateOptions } = helpers;

  useEffect(() => {
    setMemberCardsExpanded(Array(members.length).fill(false));
  }, [members?.length]);

  const getEntitlementContentForMember = (member: Member) => {
    const entitlementSummaryContent: EntitlementSummaryContentType[] = EntitlementSummaryContent;
    const selectedEntitlements = member.entitlements.filter((x) => x.selected);
    const selectedProductCodes = [] as string[];

    selectedEntitlements.forEach((x) => selectedProductCodes.push(x.productCode));

    const selectedContent = entitlementSummaryContent.find((x) =>
      selectedProductCodes.every((selectedCode) => x.productCode.some((code) => code === selectedCode)),
    );

    return selectedContent?.description ?? [''];
  };

  const toggleExpandedHandler = (expanded: boolean, index: number) => {
    const newMemberCardsExpanded = [...memberCardsExpanded];
    newMemberCardsExpanded[index] = !expanded;
    setMemberCardsExpanded(newMemberCardsExpanded);
  };

  const getPrice = (price: number) => (
    <Box display="flex" flexDirection="column" style={{ textAlign: 'left' }}>
      <Box display="flex" flexDirection="row" alignItems="baseline" fontSize="28px">
        {formatter.format(price)}
        <StyledPriceTypography variant="h5">&nbsp;{paymentFrequency ? 'per year' : 'per month'}</StyledPriceTypography>
      </Box>
      <StyledPriceTypography variant="h5">including tax</StyledPriceTypography>
    </Box>
  );

  const ipidHandler = (member : Partial<Member>, setIpidLoading : Dispatch<SetStateAction<boolean>>) => {
    setIpidLoading(true);
    const dob = dayjs(`${member.dob?.year}-${member.dob?.month}-${member.dob?.day}`).format('DD/MM/YYYY').toString();
    const memberId = `${member.firstname} ${member.lastname} ${dob}`;
    const request : GetMemberIpidType = {
      id: memberId,
      quoteKey: initialQuoteValues.quoteKey,
      quoteNumber: initialQuoteValues.quoteNumber,
    };

    getMemberIpid(request).then((result) => {
      const url = window.URL.createObjectURL(new Blob([result], { type: 'application/pdf' }));
      window.open(url, '_blank');
      setIpidLoading(false);
    }).catch((err) => {
      HandleError(err, dispatch);
      setIpidLoading(false);
    });
  };

  type MemberCardContentType = {
    value: string;
    subTitle?: string;
    header?: string;
    body?: string;
  };

  const specialistConsultationContent : MemberCardContentType[] = [
    {
      value: 'Unlimited',
      header: 'Unlimited consultations',
      body: 'If you need to see a specialist as part of your treatment, you will have cover for unlimited consultations so long as your membership covers them.',
    },
    {
      value: '3',
      header: 'Up to 3 specialist consultations',
      body: 'Only applies to Outpatient diagnosis and care or Inpatient and day patient care options. If you have both, the 3 are shared across options.',
    },
  ];

  const ncdContent : MemberCardContentType[] = [
    {
      value: 'Yes',
      subTitle: 'You chose to protect your no claims discount',
      body: 'If you claim this year, you’ll still have a discount if you renew',
    },
    {
      value: 'No',
      subTitle: 'You did not choose to protect your no claims discount',
      body: 'Any claims this year will have a bigger impact on your price if you renew next year',
    },
  ];

  return (
    <>
      {members.map((member, index) => (
        <AddMemberCardDetailsCardWrapper
          key={`${member.firstname + index}`}
          className="add-member-card-details-wrapper"
        >
          <AddMemberDetailsCard
            policyHolder={member.policyHolder}
            memberDetailsProps={member}
            expanded={memberCardsExpanded[index]}
            toggleExpanded={(expanded) => {
              toggleExpandedHandler(expanded, index);
            }}
            memberIndex={index}
            statusText={getPrice(paymentFrequency ? member.annualPremium : member.monthlyPremium)}
            showStatusText
            showMore
            padding="0"
            ipidHandler={(memberDetails, setIpidLoading) => ipidHandler(memberDetails, setIpidLoading)}
            cardContentChildren={
              <>
                <AccordionSummaryDetails
                  title="Personal Details"
                  subtitle="Your name, contact details and date of birth"
                  expanded={memberCardsExpanded[index]}
                  ariaControls={`panel1a-content-personal-detail-${index}`}
                  detailsChildren={
                    <ChildrenContainer data-testid="accordionContent">
                      <AnswerWrapper>
                        <H3 bold>Full name</H3>
                        <H5 className="axa-mask">
                          {member.firstname} {member.lastname}
                        </H5>
                        <H3 bold>Date of birth</H3>
                        <H5 className="axa-mask">
                          {dayjs(`${member.dob.year}-${member.dob.month}-${member.dob.day}`).format('Do MMM YYYY')}
                        </H5>

                        <AddressContainer>
                          <H3 bold>Address</H3>
                          <H5 className="axa-mask">{member.addressLine1}</H5>
                          <H5 className="axa-mask">{member.addressLine2}</H5>
                          <H5 className="axa-mask">{member.city}</H5>
                          <H5 className="axa-mask">{member.postcode}</H5>
                        </AddressContainer>
                        {index === 0 && (
                          <>
                            <H3 bold>Email</H3>
                            <H5 className="axa-mask">{member.email}</H5>
                            <H3 bold>Phone number(s)</H3>
                            <H5 className="axa-mask">{member.phone}</H5>
                          </>
                        )}
                      </AnswerWrapper>
                      {initialQuoteValues.quoteStatus !== 'Purchased' && (
                        <ButtonHolder>
                          <Button
                            variant="outlined"
                            data-testid="editBtn"
                            size="large"
                            onClick={() => {
                              history(routes.additionalMembers, navigateOptions);
                            }}
                          >
                            Change
                          </Button>
                        </ButtonHolder>
                      )}
                    </ChildrenContainer>
                  }
                />
                <AccordionSummaryDetails
                  title="Your health questions"
                  subtitle="View your answers"
                  expanded={memberCardsExpanded[index]}
                  ariaControls={`panel1a-content-health-questions-${index}`}
                  detailsChildren={
                    <ChildrenContainer>
                      <AnswerWrapper>
                        {majorHealthQuestions.map((item, i) => (
                          <Box key={`${item.question + i}`}>
                            <H3 bold>{item.question}</H3>
                            <H5>{member.majorHealthQuestions[item.code]}</H5>
                          </Box>
                        ))}
                      </AnswerWrapper>
                      {initialQuoteValues.quoteStatus !== 'Purchased' && (
                        <ButtonHolder>
                          <Button
                            variant="outlined"
                            data-testid="ChangeBtn"
                            size="large"
                            onClick={() => {
                              history(routes.majorHealthQuestions, navigateOptions);
                            }}
                          >
                            Change
                          </Button>
                        </ButtonHolder>
                      )}
                    </ChildrenContainer>
                  }
                />
                <AccordionSummaryDetails
                  title="Cover needs"
                  subtitle="Please make sure the care options you've chosen meet your needs"
                  expanded={memberCardsExpanded[index]}
                  ariaControls={`panel1a-content-cover-needs-${index}`}
                  detailsChildren={
                    <ChildrenContainer data-testid="accordionContent">
                      <EntitlementHolder>
                        <MessageBoxWrapper>
                          <MessageBoxStyled $noMarginBottom>
                            <CustomText key="generic-content-text" $isBulletPoint={false} $isNewLine>
                              This plan is for people who:
                            </CustomText>
                            {getEntitlementContentForMember(member).map((content, i) => (
                              <CustomText
                                key={`content-text-${i.toString()}`}
                                $isBulletPoint={content.includes('\t')}
                                $isNewLine={content.includes('\n')}
                                bold={content.includes('\b')}
                              >
                                {content.replace('\b', '')}
                              </CustomText>
                            ))}
                          </MessageBoxStyled>
                        </MessageBoxWrapper>
                        {member.entitlements.map((entitlement) => (
                          <PriceCardSmall
                            key={`price-card-small-${entitlement.productCode + index}`}
                            title={
                              helpers.initialEntitlements.find((e) => e.productCode === entitlement?.productCode)
                                ?.name || ''
                            }
                            selected={entitlement.selected}
                            monthlyPremium={entitlement.monthlyPremium}
                            annualPremium={entitlement.annualPremium}
                            paymentFrequency={paymentFrequency}
                            entitlementDetails={Object.assign(
                              {},
                              ...entitlementContent.filter(
                                (details) => details.productCode === entitlement.productCode,
                              ),
                            )}
                          />
                        ))}
                      </EntitlementHolder>
                      {initialQuoteValues.quoteStatus !== 'Purchased' && (
                        <ButtonHolder>
                          <Button
                            variant="outlined"
                            data-testid="ChangeBtn"
                            size="large"
                            onClick={() => {
                              history(routes.entitlements, navigateOptions);
                            }}
                          >
                            Change
                          </Button>
                        </ButtonHolder>
                      )}
                    </ChildrenContainer>
                  }
                />
                <AccordionSummaryDetails
                  title="Your Excess"
                  subtitle={`You chose an excess of £${member.excess}`}
                  expanded={memberCardsExpanded[index]}
                  ariaControls={`panel1a-content-excess-amount-${index}`}
                  detailsChildren={
                    <ChildrenContainer data-testid="accordionContent">
                      <SectionHeaderWrapper>
                        <H3 bold>Your selected excess</H3>
                        <Box display="flex" gap="3px">
                          <H5>Your cover has an excess amount of</H5>
                          <H5 bold>{`£${member.excess}`}</H5>
                        </Box>
                      </SectionHeaderWrapper>
                      <NotificationWrapper>
                        {member.excess !== '0' ? (
                          <>
                            <Text display="inline">When you claim,</Text>
                            <Text bold display="inline">
                              {` you only pay the first £${member.excess} towards the cost and we’ll pay the rest.`}
                            </Text>
                            <Text display="inline">
                              {` If the cost is less than £${member.excess}, we’ll ask you to pay
                                    for it yourself, and for any further claim until you
                                    reach your £${member.excess} excess for the
                                    year. Once you’ve reached the total of your excess, you
                                    won’t need to pay it again for the year.`}
                            </Text>
                          </>
                        ) : (
                          <Text display="inline">
                            You won’t need to pay towards the cost of treatment or tests, so long as your plan covers
                            these in full.
                          </Text>
                        )}
                      </NotificationWrapper>
                      {initialQuoteValues.quoteStatus !== 'Purchased' && (
                        <ButtonHolder>
                          <Button
                            variant="outlined"
                            data-testid="editBtn"
                            size="large"
                            onClick={() => {
                              history(routes.excess, navigateOptions);
                            }}
                          >
                            Change
                          </Button>
                        </ButtonHolder>
                      )}
                    </ChildrenContainer>
                  }
                />
                {member.consultationConfig?.options?.length > 0 && (
                  <AccordionSummaryDetails
                    title="Specialist consultations"
                    subtitle="You selected"
                    subtitleSelection={
                      member?.consultationConfig?.options?.find((option) => option.value === member.consultation)
                        ?.displayName
                    }
                    expanded={memberCardsExpanded[index]}
                    ariaControls={`panel1a-content-consultation-allowance-${index}`}
                    detailsChildren={
                      <ChildrenContainer data-testid="accordionContent">
                        <SectionHeaderWrapper>
                          <H3 bold>
                            {
                              specialistConsultationContent.find((content) => content.value === member?.consultation)
                                ?.header
                            }
                          </H3>
                          <H5>
                            {
                              specialistConsultationContent.find((content) => content.value === member?.consultation)
                                ?.body
                            }
                          </H5>
                        </SectionHeaderWrapper>
                        {initialQuoteValues.quoteStatus !== 'Purchased' && (
                          <ButtonHolder>
                            <Button
                              variant="outlined"
                              data-testid="editBtn"
                              size="large"
                              onClick={() => {
                                history(routes.consultations, navigateOptions);
                              }}
                            >
                              Change
                            </Button>
                          </ButtonHolder>
                        )}
                      </ChildrenContainer>
                    }
                  />
                )}
                <AccordionSummaryDetails
                  title="Protecting your no claims discount"
                  subtitle={ncdContent.find((content) => content?.value === member?.ncdProtection)?.subTitle}
                  expanded={memberCardsExpanded[index]}
                  ariaControls={`panel1a-content-ncd-${index}`}
                  detailsChildren={
                    <ChildrenContainer data-testid="accordionContent">
                      <SectionHeaderWrapper>
                        <H5>{ncdContent.find((content) => content?.value === member?.ncdProtection)?.body}</H5>
                      </SectionHeaderWrapper>
                      {initialQuoteValues.quoteStatus !== 'Purchased' && (
                        <ButtonHolder>
                          <Button
                            variant="outlined"
                            data-testid="ChangeBtn"
                            size="large"
                            onClick={() => {
                              history(routes.ncd, navigateOptions);
                            }}
                          >
                            Change
                          </Button>
                        </ButtonHolder>
                      )}
                    </ChildrenContainer>
                  }
                />
              </>
            }
          />
        </AddMemberCardDetailsCardWrapper>
      ))}
      {initialQuoteValues.quoteStatus !== 'Purchased' && (
        <ButtonWrapper>
          <Button
            variant="outlined"
            data-testid="addRemoveMemberBtn"
            size="large"
            onClick={() => {
              history(routes.additionalMembers, navigateOptions);
            }}
          >
            Add or remove member
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
}
