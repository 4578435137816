import { Box } from '@mui/material';
import { H5, Text } from '@digitalportal-ui/core';
import { DownloadCardStyle, LinkStyle, DownloadIcon, CardContainer, IconHolder } from './styles';
import { ReactComponent as DocumentIcon } from '../../../assets/svg/document_icon.svg';

export type DownloadCardProps = {
  title: string;
  subtitle: string;
  link: string;
  urlText: string;
  fileSizeText: string;
};

export default function DownloadCard({ title, subtitle, link, urlText }: DownloadCardProps): React.JSX.Element {
  return (
    <DownloadCardStyle elevation={0} data-testid={`downloadCard-${title.replace(/\s+/g, '-').toLowerCase()}`}>
      <CardContainer>
        <IconHolder>
          <DocumentIcon />
        </IconHolder>
        <Box marginLeft="16px">
          <H5 bold>{title}</H5>
          <Text mb={1}>{subtitle}</Text>
          <LinkStyle aria-label={`${urlText} ${title}`} href={link} rel="noopener" target="_blank">
            {urlText}
            <DownloadIcon />
          </LinkStyle>
        </Box>
      </CardContainer>
    </DownloadCardStyle>
  );
}
