import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Field, FieldProps, ErrorMessage } from 'formik';
import { theme } from '@digitalportal-ui/core';
import { DatePickerWrapper, StyledInputLabel } from './styles';
import { ErrorMessageText } from '../TextInputField/styles';
import { ReactComponent as Calendar } from '../../../assets/svg/calendar.svg';
import MemberValidation from '../../../modules/validation/memberValidation.json';

export interface ResponsiveDatePickerProps extends FieldProps {
  name: string;
  label: string;
  testId: string;
  DatePickerProps?: DatePickerProps<dayjs.Dayjs>;
}

function ResponsiveDatePicker({
  form,
  field: { value, name },
  label,
  testId,
  ...DateFieldPickerProps
}: ResponsiveDatePickerProps): React.JSX.Element {
  const currentError = form.errors[name];
  const showError = Boolean(currentError && form.touched[name]);
  const maxDate = dayjs().add(MemberValidation.validation.coverStartDate.maxDateDiff.value, 'day');
  const datePickerRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  return (
    <DatePickerWrapper data-testid={testId}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
        <StyledInputLabel>{label}</StyledInputLabel>
        <DatePicker
          inputRef={datePickerRef}
          format="DD/MM/YYYY"
          value={dayjs(value)}
          maxDate={maxDate}
          disablePast
          disableHighlightToday
          desktopModeMediaQuery={`@media (min-width:${theme.breakpoints.values.md + 1}px)`}
          sx={{ style: { marginLeft: 38, marginTop: window.innerHeight > 740 ? 3 : -180 } }}
          {...DateFieldPickerProps.DatePickerProps}
          onChange={(selectedDate) => {
            form.setFieldValue(name, selectedDate?.format('YYYY-MM-DD'));
          }}
          slots={{
            openPickerIcon: Calendar,
          }}
          slotProps={{
            openPickerIcon: {
              'data-testid': 'CalendarTodayIcon',
            },
            toolbar: {
              hidden: true,
            },
            textField: {
              error: showError,
              fullWidth: true,
              inputProps: {
                'data-testid': 'coverstartdate-input',
              },
              InputProps: {
                endAdornment: <Calendar data-testid="CalendarTodayIcon" />,
              },
            },
          }}
        />
        <ErrorMessage
          name={name}
          render={(message) => (
            <ErrorMessageText textAlign="left" data-testid={`error-message-${testId}`}>
              {message}
            </ErrorMessageText>
          )}
        />
      </LocalizationProvider>
    </DatePickerWrapper>
  );
}

export default function DatePickerField({
  name,
  label = 'Choose start date',
  testId,
  ...props
}: Partial<ResponsiveDatePickerProps>): React.JSX.Element {
  return <Field {...props} name={name} label={label} testId={testId} component={ResponsiveDatePicker} />;
}
