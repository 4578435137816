import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import { Box, Divider, useMediaQuery } from '@mui/material';
import {
  Text,
  H4,
  colors,
  Modal,
  HospitalMarkerProps,
  theme,
  setBounds,
  HospitalsView,
  ReactGoogleMaps,
  Banner,
  H3,
} from '@digitalportal-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { TStore } from '../../../redux/store';
import RadioBlock from '../../molecules/RadioBlock/RadioBlock';
import { InfoLink } from '../../atoms/Link/Link';
import routes from '../../../enums/routes';
import RadioVariants from '../../../enums/radioVariants';
import { updateQuoteHospitalNetwork } from '../../../redux/slices/quote';
import { updateHelperIsProgressShown, updateModalInfo } from '../../../redux/slices/helpers';
import Footer from '../../templates/Footer/Footer';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import {
  PageWrapper,
  PageIntroductionWrapper,
  RadioBlockContainer,
  RadioBlockWrapper,
  RadioContent,
  NetworkContainer,
  NetworkWrapper,
  PostcodeContainer,
  UkMap,
  MapContainer,
  NetworkOptionsContainer,
  SearchButton,
  PostcodeFieldWrapper,
  SearchPostcodeContainer,
  PhinLinkIcon,
  CustomText,
  ModalPhinLink,
  PhinWrapper,
  PhinText,
} from './styles';
import NetworkContent from '../../../content/networkContent.json';
import { useGetPrice } from '../../../hooks/useGetPrice';
import { AddProductAddonToDataLayer } from '../../../lib/tagManager/commonFunctions';
import config from '../../../config/config.json';
import NetworkCodes from '../../../enums/networks';
import { Providers } from '../../../redux/slices/helpers/helpers.types';
import { getHospitals } from '../../../lib/utils/services/productService';
import HandleError from '../../../lib/utils/handleError';
import TextInputField from '../../molecules/TextInputField';
import { PostcodeSchema } from './validationSchema';
import { ReactComponent as PhinLogo } from '../../../assets/svg/phin_logo.svg';

const NAME = 'hospitalNetwork';
const isRHLOn = config.FEATURES.RHL === 'true';
const isMapOn = config.FEATURES.MAP === 'true';

export default function Hospitals(): React.JSX.Element {
  // Hooks
  const history = useNavigate();
  const dispatch = useDispatch();
  const largeDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const smallDesktopAndAbove = useMediaQuery(theme.breakpoints.up('lg'));
  const tabletAndBelow = useMediaQuery(theme.breakpoints.down('lg'));
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const mapContext = ReactGoogleMaps.useMap('hospital-map');
  const { getPrice, loading } = useGetPrice();

  // Getting from redux state
  const navigateOptions = useSelector((state: TStore) => state.helpersState.helpers.navigateOptions);
  const providers = useSelector((state: TStore) => state.helpersState.helpers.providers);
  const initialQuote = useSelector((state: TStore) => state.quoteState.quote);

  // Local state
  const [showMap, setShowMap] = useState(isMapOn);
  const [markers, setMarkers] = useState([] as HospitalMarkerProps[]);
  const [networks, setNetworks] = useState({} as Providers);
  const [fetchingNetworks, setFetchingNetworks] = useState(false);
  const [displayPostcode, setDisplayPostcode] = useState('');
  const [openOptionSelected, setOpenOptionSelected] = useState(false);
  const [showPostcodeField, setShowPostcodeField] = useState(false);
  const [postcodeErrored, setPostcodeErrored] = useState(false);
  const [currentNetworkOptionModal, setCurrentNetworkOptionModal] = useState<{ open: boolean; value: string }>({
    open: false,
    value: '',
  });

  const { members, hospitalNetworkConfig, hospitalNetwork } = initialQuote;
  const hospitalNetworkOptions = hospitalNetworkConfig.options;
  const initialValues = {
    [NAME]: hospitalNetwork || 'GuidedOption',
    postcode: '',
  };
  const noConsultationOptionsForEveryMember = members.every(
    (member) => member.consultationConfig?.options?.length === 0,
  );

  const showPostcode = showMap && !openOptionSelected && !showPostcodeField;
  const showPhinLink = (networkOption: string) => networkOption === NetworkCodes.openOption && showMap;

  // Update progress bar
  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: true }));
  }, [dispatch]);

  // Setting up initial data on first render only
  useEffect(() => {
    setNetworks(providers);
    setDisplayPostcode(members.find((mem) => mem.policyHolder)?.postcode ?? '');
    setOpenOptionSelected(hospitalNetwork === NetworkCodes.openOption);
  }, []);

  // Updating when to show the map
  useEffect(() => {
    const isMapEnabled = isMapOn && providers.networks.some((x) => x.hospitals.length > 0);
    setShowMap(isMapEnabled);
  }, [providers]);

  // Setting bounds when viewport is changed or number of markers have changed
  useEffect(() => {
    if (mapContext && markers.length > 0) {
      setBounds(mapContext, markers);
    }
  }, [mobile, smallDesktopAndAbove, mapContext, markers]);

  // Setting up markers for the map and closing any open info windows
  useEffect(() => {
    if (hospitalNetwork === NetworkCodes.openOption) {
      setOpenOptionSelected(true);
    } else {
      setOpenOptionSelected(false);
      const hospitals = networks?.networks?.find((x) => x.network === hospitalNetwork)?.hospitals;

      if (hospitals) {
        setMarkers(
          hospitals.map(
            (hospital) =>
              ({
                position: { lat: hospital.lat, lng: hospital.lng },
                title: hospital.hospitalName,
                hospitalInfo: {
                  hospital: hospital.hospitalName,
                  address: hospital.hospitalAddress,
                  drivingDistance: hospital.drivingDistance,
                  specialities: [],
                },
                infoWindowMaxWidth: 280,
              } as HospitalMarkerProps),
          ),
        );

        const event = new CustomEvent('close-info-window');
        mapContext?.getDiv().dispatchEvent(event);
      } else {
        setMarkers([]);
      }
    }
  }, [hospitalNetwork, networks]);

  useCustomEventListener('pageNavigationBack', () => {
    if (isRHLOn) {
      if (noConsultationOptionsForEveryMember) {
        history(routes.guidedSelection, navigateOptions);
      } else {
        history(routes.consultations, navigateOptions);
      }
    } else {
      history(routes.excess, navigateOptions);
    }
  });

  // Setup of the Map component and changing behaviour based off of specific dependencies
  const mapComponent = useMemo(() => {
    let viewButtonMarginBottom = '0';
    let height = '445px';

    if (tabletAndBelow && !mobile && postcodeErrored) {
      viewButtonMarginBottom = '25px';
    }

    if (mobile) {
      height = '320px';
    } else if (largeDesktop) {
      height = '465px';
    }

    return (
      markers.length > 0 && (
        <HospitalsView
          mapProps={{
            markers,
            defaultCenter: { lat: networks?.postcodeLat, lng: networks?.postcodeLng },
            mapId: config.MAP_ID,
            style: { height, width: '100%' },
            reuseMaps: true,
          }}
          bannerText={`This shows your closest ${
            networks?.networks?.find((x) => x.network === hospitalNetwork)?.hospitals.length
          } hospitals and other facilities on this list`}
          viewButtonMarginBottom={viewButtonMarginBottom}
        />
      )
    );
  }, [markers, networks, mobile, largeDesktop, tabletAndBelow, hospitalNetwork, postcodeErrored]);

  const radioClickHandler = (value: string) => {
    dispatch(updateQuoteHospitalNetwork({ hospitalNetwork: value }));
    AddProductAddonToDataLayer(value);
    getPrice({ selectedHospitalNetwork: value });
  };

  function Submitter() {
    const { submitForm, errors, touched } = useFormikContext<{ [NAME]: string; postcode: string }>();

    useEffect(() => {
      setPostcodeErrored(errors.postcode !== undefined && touched.postcode === true);
    }, [errors, touched]);

    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }

  const pageSubtitle = useMemo(
    () =>
      showMap ? (
        <Box textAlign="center">
          <H3 mb={1}>We can give you a choice from our list, or you can choose yourself.</H3>
          <H3 mb={1}>
            The nearest hospitals you have access to with the Guided and Flexible options are shown below. With the Open
            option, you have access to these hospitals plus any other private hospital you like.
          </H3>
          <H3>
            The hospital you use will depend on the type of specialist you see and the condition you claim for. For the
            Guided option you usually won’t need to travel more than 20 miles (5 miles in London) from your chosen
            location.
          </H3>
        </Box>
      ) : (
        <H3>
          We can give you a choice from our list, or you can choose yourself. Whichever option you select, we can help
          you find a specialist and book your appointment for you if you prefer
        </H3>
      ),
    [showMap],
  );

  const handleSearchOnClick = async (
    searchPostcode: string,
    isValid: boolean,
    keyDownEvent?: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if ((!keyDownEvent || keyDownEvent.key === 'Enter') && searchPostcode && isValid) {
      try {
        setFetchingNetworks(true);
        const response = await getHospitals(searchPostcode);
        setNetworks(response);
        setDisplayPostcode(searchPostcode);
        setFetchingNetworks(false);
        setShowPostcodeField(false);
      } catch (err) {
        setFetchingNetworks(false);
        HandleError(err, dispatch, 'Something went wrong. Unable to search using the provided postcode');
      }
    }
  };

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <PageWrapper display="flex" flexDirection="column" alignItems="center">
          <PageIntroductionWrapper>
            <PageIntroduction
              title="How would you like to choose your specialists?"
              titleTestId="title"
              subtitle={pageSubtitle}
              subtitleTestId="subtitle"
              includeWrapper={false}
              modalTitle="How does this work?"
            >
              <Box>
                <Text mb={1}>If you’re happy for us to source your specialist, this will lower your price.</Text>
                <Text semibold mb={1}>
                  Guided option:
                </Text>
                <Text display="list-item" ml={4}>
                  We’ll only cover treatment with a specialist we’ve sourced for you at a hospital we have agreed.
                </Text>
                <Text display="list-item" ml={4}>
                  We can book your appointment for you too.
                </Text>
                <Text display="list-item" ml={4} mb={1}>
                  We’ll tell you which hospital to go to. The specialist may not work at your nearest hospital.
                </Text>
                <Text semibold mb={1}>
                  Flexible option:
                </Text>
                <Text display="list-item" ml={4}>
                  You can choose any specialist that we work with.
                </Text>
                <Text display="list-item" ml={4}>
                  We’ll pay for treatment up to our agreed rates. If the specialist or hospital charges more, you may
                  need to pay the difference.
                </Text>
                <Text display="list-item" ml={4}>
                  You can use any hospital in our Directory of Hospitals.
                </Text>
                <Text display="list-item" ml={4} mb={1}>
                  If you prefer, we can find a specialist for you and book your appointment.
                </Text>
                <Text semibold mb={1}>
                  Open option:
                </Text>
                <Text display="list-item" ml={4}>
                  You can choose any specialist that we work with.
                </Text>
                <Text display="list-item" ml={4}>
                  We’ll pay the specialist’s and hospital’s fees in full.
                </Text>
                <Text display="list-item" ml={4}>
                  You can use any private hospital in the UK.
                </Text>
                <Text display="list-item" ml={4} mb={1}>
                  If you prefer, we can find a specialist for you and book your appointment.
                </Text>
                <Text>Please see our handbook for more about the specialists and hospitals we cover.</Text>
              </Box>
            </PageIntroduction>
          </PageIntroductionWrapper>
          <NetworkContainer>
            <NetworkWrapper>
              <Box sx={showMap ? { width: { lg: '50%' } } : {}}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={PostcodeSchema}
                  validateOnBlur
                  enableReinitialize
                  onSubmit={(values) => {
                    if (isRHLOn) {
                      history(routes.excess, navigateOptions);
                    } else {
                      history(routes.ncd, navigateOptions);
                    }
                    dispatch(updateQuoteHospitalNetwork({ hospitalNetwork: values[NAME] }));
                  }}
                >
                  {({ values, isValid }) => (
                    <Form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
                      <NetworkOptionsContainer>
                        {showPostcode && (
                          <PostcodeContainer>
                            <Text mr={2}>
                              Based on your selection, hospitals / facilities shown are closest to{' '}
                              <Text display="inline" bold>
                                {displayPostcode.toUpperCase()}
                              </Text>
                            </Text>
                            <InfoLink
                              testId="change-location-link"
                              link="Change location"
                              infoLinkIcon={
                                <FmdGoodIcon style={{ marginRight: '8px' }} htmlColor={colors.oceanBlue300} />
                              }
                              onClickHandler={() => {
                                setShowPostcodeField(true);
                              }}
                            />
                          </PostcodeContainer>
                        )}
                        {showPostcodeField && !openOptionSelected && (
                          <SearchPostcodeContainer onKeyDown={(e) => handleSearchOnClick(values.postcode, isValid, e)}>
                            <PostcodeFieldWrapper>
                              <TextInputField
                                wrapperMarginDisabled
                                label="Enter postcode"
                                name="postcode"
                                testId="textInputField-postcode"
                              />
                            </PostcodeFieldWrapper>
                            <SearchButton
                              loading={fetchingNetworks}
                              type="button"
                              disabled={values.postcode.length === 0}
                              onClick={() => handleSearchOnClick(values.postcode, isValid)}
                            >
                              Search
                            </SearchButton>
                          </SearchPostcodeContainer>
                        )}
                        <RadioBlockContainer $mapIncluded={showMap}>
                          {hospitalNetworkOptions?.map((option, index) => (
                            <RadioBlockWrapper
                              $mapIncluded={showMap}
                              key={option.value}
                              onClick={(e) => {
                                e.preventDefault();
                                radioClickHandler(option.value);
                              }}
                              data-testid={`radioHospitalButton-${index}`}
                            >
                              <RadioBlock variant={RadioVariants.brick} value={option.value} name={NAME}>
                                <RadioContent>
                                  <H4 bold color={colors.darkGrey}>
                                    {option.displayName}
                                  </H4>
                                  <Text sx={{ mb: { md: '8px' } }}>
                                    {NetworkContent.find((x) => x.networkValue === option.value)?.description}
                                  </Text>
                                  {showPhinLink(option.value) && (
                                    <Box sx={{ mb: { md: '8px' } }}>
                                      <InfoLink
                                        link="See a list of private hospitals near you"
                                        infoLinkIcon={<PhinLinkIcon />}
                                        testId="phin"
                                        onClickHandler={() => {
                                          window.open(
                                            `https://www.phin.org.uk/search/hospitals?s_location_input=${
                                              members.find((mem) => mem.policyHolder)?.postcode
                                            }&s_location_coordinates=${providers.postcodeLat}%2C${
                                              providers.postcodeLng
                                            }`,
                                            '_blank',
                                          );
                                        }}
                                      />
                                    </Box>
                                  )}
                                  <InfoLink
                                    aria-label="More-about-specialist-choice-and-fees-link"
                                    testId={`More-about-specialist-choice-and-fees-${index}`}
                                    onClickHandler={() => {
                                      setCurrentNetworkOptionModal({
                                        ...currentNetworkOptionModal,
                                        open: true,
                                        value: option.value,
                                      });
                                      dispatch(
                                        updateModalInfo({
                                          isModalOpen: true,
                                          modalTitle:
                                            NetworkContent.find((x) => x.networkValue === option.value)?.header ?? '',
                                        }),
                                      );
                                    }}
                                    link="More about specialist choice and fees"
                                  />
                                </RadioContent>
                              </RadioBlock>
                            </RadioBlockWrapper>
                          ))}
                        </RadioBlockContainer>
                        <Modal
                          variant="info"
                          title={
                            NetworkContent.find((x) => x.networkValue === currentNetworkOptionModal.value)?.header ?? ''
                          }
                          open={currentNetworkOptionModal.open}
                          onClose={() => {
                            setCurrentNetworkOptionModal({ ...currentNetworkOptionModal, open: false });
                            dispatch(updateModalInfo({ isModalOpen: false, modalTitle: '' }));
                          }}
                          dataTestid={`network-option-modal-${currentNetworkOptionModal.value}`}
                        >
                          <Box>
                            <Box>
                              {NetworkContent.find((x) => x.networkValue === currentNetworkOptionModal.value)?.body.map(
                                (text: string, index: number) => (
                                  <CustomText
                                    $isBulletPoint={text.includes('\t')}
                                    $isNewLine={text.includes('\n')}
                                    key={text.concat(index.toString())}
                                    variant="body1"
                                  >
                                    {text}
                                  </CustomText>
                                ),
                              )}
                            </Box>
                            {currentNetworkOptionModal.value === NetworkCodes.openOption ? (
                              <>
                                <Divider sx={{ marginY: '24px' }} />
                                <PhinWrapper>
                                  <Box alignSelf="center">
                                    <PhinLogo width="90px" height="auto" />
                                  </Box>
                                  <PhinText>
                                    You can find independent information about private consultants and hospitals,
                                    including costs and quality of care, from the Private Healthcare Information
                                    Network:{' '}
                                    <ModalPhinLink
                                      to="www.phin.org.uk"
                                      target="_blank"
                                      aria-label="AXA Health Plan Handbook"
                                      color={colors.oceanBlue300}
                                    >
                                      www.phin.org.uk
                                    </ModalPhinLink>
                                  </PhinText>
                                </PhinWrapper>
                              </>
                            ) : null}
                          </Box>
                        </Modal>
                        <Submitter />
                      </NetworkOptionsContainer>
                    </Form>
                  )}
                </Formik>
              </Box>
              {showMap &&
                (!openOptionSelected ? (
                  <MapContainer $postcodeErrored={postcodeErrored} $showPostcodeField={showPostcodeField}>
                    {mapComponent}
                  </MapContainer>
                ) : (
                  <Box sx={{ width: { lg: '50%' } }}>
                    <Banner
                      testId="hospitals-view"
                      text="With this option you can use any private hospital in the UK"
                    />
                    <UkMap role="img" aria-label="uk map" />
                  </Box>
                ))}
            </NetworkWrapper>
            <PageNavigation isNextButtonDisabled={loading} />
          </NetworkContainer>
        </PageWrapper>
        <Footer data-testid="footer" isPrice priceLoading={loading} />
      </Main>
    </>
  );
}
