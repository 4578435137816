import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as WarningIcon } from '../../../assets/svg/warning_icon.svg';
import { SnackBarMessage, SnackbarWrapper } from './styles';
import { updateApiError } from '../../../redux/slices/helpers';
import { TStore } from '../../../redux/store';

export type MessageSnackBarProps = {
  openMessage?: boolean;
  message?: string;
};

export default function MessageSnackBar({ openMessage, message }: MessageSnackBarProps): React.JSX.Element {
  const dispatch = useDispatch();
  const showApiError = useSelector((state: TStore) => state.helpersState.helpers.apiError.hasError) || openMessage;
  const errorMessage = useSelector((state: TStore) => state.helpersState.helpers.apiError.message);

  const handleClose = () =>
    dispatch(
      updateApiError({
        apiError: {
          hasError: false,
          message: '',
        },
      }),
    );

  return (
    <SnackbarWrapper
      open={showApiError}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={10000}
      onClose={handleClose}
      transitionDuration={{ exit: 0 }}
    >
      <SnackBarMessage icon={<WarningIcon />}>
        {message || errorMessage || 'Sorry something has gone wrong, please try again or call us for support'}
        <IconButton style={{ display: 'inline' }} size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </SnackBarMessage>
    </SnackbarWrapper>
  );
}
