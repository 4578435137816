import { H1, H3 } from '@digitalportal-ui/core';
import { Wrapper, HeadingWrapper, AnimationWrapper } from './styles';
import * as animationData from '../../../assets/animationsjsons/cyclist.json';

export type LoaderProps = {
  title?: string;
  subtitle?: string;
  includeWrapper?: boolean;
  circleBackground?: string;
};

export default function Loader({ title, subtitle, includeWrapper = true }: LoaderProps): React.JSX.Element {
  const component = (
    <>
      <AnimationWrapper
        loop
        autoplay
        animationData={animationData}
      />
      {title && (
        <HeadingWrapper>
          <H1 bold>{title}</H1>
        </HeadingWrapper>
      )}
      {subtitle && <H3>{subtitle}</H3>}
    </>
  );

  return includeWrapper ? (
    <Wrapper data-testid="loader-wrapper" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      {component}
    </Wrapper>
  ) : (
    component
  );
}
