import { Box } from '@mui/material';
import StyledAccordion from '../../molecules/StyledAccordion/StyledAccordion';
import CoreCard from '../../molecules/CoreCard/CoreCard';
import AccordionVariants from '../../../enums/accordionVariants';
import ImportantInformationContent from '../../../content/importantInformationContent.json';
import { CustomText } from './styles';

export default function ImportantInformation(): React.JSX.Element {
  return (
    <CoreCard
      title="Key things your membership doesn’t cover"
      subTitle="Please read this important information about what isn’t covered by this plan"
      bubbleTitle="For every member"
      testId="important-information-section"
      cardBodyMarginBottom="0"
      cardBodyMobileMarginBottom="0"
      linkText="View members"
    >
      <Box key="information-accordion-wrapper">
        {ImportantInformationContent.map((content, index) => (
          <StyledAccordion
            key={content.header.concat(index.toString())}
            title={content.header}
            variant={AccordionVariants.coreCard}
          >
            {content.body.map((text: string) => (
              <CustomText
                key={text.concat(index.toString())}
                $isBulletPoint={text.includes('\t')}
                $isNewLine={text.includes('\n')}
                variant="body1"
              >
                {text}
              </CustomText>
            ))}
          </StyledAccordion>
        ))}
      </Box>
    </CoreCard>
  );
}
