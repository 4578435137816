/* eslint-disable no-debugger */
import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormikContext, FormikErrors } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TagManager from 'react-gtm-module';
import { TStore } from '../../../redux/store';
import routes from '../../../enums/routes';
import PriceCard from '../../molecules/PriceCard/PriceCard';
import {
  updateQuoteMonthlyPremiums,
  EntitlementPriceType,
  Member,
  Quote,
  updateMember,
} from '../../../redux/slices/quote';
import { updateHelperIsProgressShown } from '../../../redux/slices/helpers';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import Footer from '../../templates/Footer/Footer';
import Header from '../../templates/Header/Header';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { Main } from '../../templates/styles';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import { PageWrapper, PageIntroductionWrapper, PageWarningContainer } from './styles';
import { getEntitlementRulesMet } from '../../../lib/utils/productRules';
import EverydayHealthcareBenefits from './EverydayHealthcareBenefits';
import { scrollToFirstError } from '../majorHealthQuestions/scrollToFirstError';
import ErrorMessageBox from '../../molecules/ErrorMessageBox/errorMessageBox';
import {
  EntitlementModalContent,
  EntitlementProductType,
  EntitlementErrorMessages,
} from '../../../redux/slices/helpers/helpers.types';
import ProductRulesMhqExclusionTypes from '../../../enums/productMhqExclusionTypes';
import EntitlementModalContentJson from '../../../content/entitlementModalContent.json';
import isQuoteUpdated from '../../../lib/utils/isQuoteUpdated';
import FlexYourOptions from './FlexYourOptions';
import MemberNoticeAndWarnings from './MemberNoticeAndWarnings';
import MultiMemberCardListItems from './MultiMemberCardListItems';
import { useGetPrice } from '../../../hooks/useGetPrice';
import configData from '../../../config/config.json';
import EntitlementsInfoModal, { EntitlementsInfoModalVariant } from '../../molecules/EntitlementsInfoModal/EntitlementsInfoModal';

const updateEntitlement = (
  entitlement: EntitlementPriceType,
  priceCardSelected: boolean,
  isSelected: boolean,
  member: Member,
): EntitlementPriceType => ({
  ...entitlement,
  selected: priceCardSelected ? false : isSelected,
  loading: member.policyHolder,
});

const isProductCodeMatchingClickedItem = (entitlement: EntitlementPriceType, clickableItemProductCode: string) => {
  if (entitlement.productCode === clickableItemProductCode) {
    return true;
  }
  return false;
};

export function getUpdatedEntitlementsWithPrices(
  initialEntitlements: EntitlementProductType[],
  memberApi: Partial<Member>,
  memberEntitlementsRedux: EntitlementPriceType[],
  updateMonthlyPremium = true,
) {
  const validProductCodes = initialEntitlements.map(({ productCode }: { productCode: string }) => productCode) ?? [];

  const validEntitlements = memberApi.entitlements
    ? memberApi.entitlements.filter(
        (entitlement: EntitlementPriceType) => validProductCodes.indexOf(entitlement.productCode) > -1,
      )
    : [];

  return validEntitlements.map((entitlementApi: EntitlementPriceType) => {
    const entitlementRedux =
      memberEntitlementsRedux.find(
        (entitlementsRedux: EntitlementPriceType) => entitlementApi.productCode === entitlementsRedux.productCode,
      ) || entitlementApi;

    return {
      ...entitlementApi,
      monthlyPremium: updateMonthlyPremium ? entitlementApi.monthlyPremium : entitlementRedux.monthlyPremium,
      annualPremium: !updateMonthlyPremium ? entitlementApi.annualPremium : entitlementRedux.annualPremium,
      price: 0,
      name: initialEntitlements.find((x) => x.productCode === entitlementApi.productCode)?.name ?? '',
    };
  });
}

export const getNewMemberValuesCardListItem = (
  valuesInitialMembers: Member[],
  clickableItemProductCode: string,
  clickedMemberIndex: number,
) =>
  valuesInitialMembers.map((stateMember, index) => {
    if (index === clickedMemberIndex) {
      return {
        ...stateMember,
        entitlements: stateMember.entitlements.map((entitlement) => {
          if (entitlement.productCode === clickableItemProductCode) {
            const isSelected =
              entitlement.productCode === clickableItemProductCode ? !entitlement.selected : entitlement.selected;

            const tagManagerArgs = {
              dataLayer: {
                event: 'entitlement_interaction',
                entitlement_product: entitlement.name,
                entitlement_category: 'core product',
                entitlement_interaction_type: isSelected ? 'add' : 'remove',
                entitlement_member: index.toString(),
              },
            };
            TagManager.dataLayer(tagManagerArgs);

            return {
              ...entitlement,
              selected: isSelected,
              loading: true,
            };
          }

          return entitlement;
        }),
      };
    }

    return stateMember;
  });

export default function EntitlementBuilderModules(): React.JSX.Element {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { getPrice, loading } = useGetPrice();
  const entitlementContent: EntitlementModalContent[] = EntitlementModalContentJson;

  const { productRules, initialEntitlements, navigateOptions, majorHealthQuestions } = useSelector(
    (state: TStore) => state.helpersState.helpers,
  );
  let initialMembers = useSelector((state: TStore) => state.quoteState.quote.members);
  const initialQuote = useSelector((state: TStore) => state.quoteState.quote);
  const quoteSnapshot = useSelector((state: TStore) => state.quoteState.quoteSnapshot);

  const [closeError, setCloseError] = useState(false);
  const [closeErrorMessage, setCloseErrorMessage] = useState('');
  const [clickedCardIndex, setClickedCardIndex] = useState<number>();
  const [openedCardIndexes, setOpenedCardIndexes] = useState<number[]>([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [infoModalVariant, setInfoModalVariant] = useState(EntitlementsInfoModalVariant.variantOne);

  // Event listener setup for Optimizely to emit the event to open with the desired variant
  useEffect(() => {
    const element = document.getRootNode();
    const handleEvent = (event: CustomEvent | Event) => {
      const variant = (event as CustomEvent).detail;
      setInfoModalVariant(variant);
      setOpenInfoModal(true);
    };

    element?.addEventListener('openInfoModal', handleEvent, false);

    return () => {
      element?.removeEventListener('openInfoModal', handleEvent, false);
    };
  }, []);

  initialMembers = initialMembers.map((member) => {
    if (!member.entitlements.length) {
      return {
        ...member,
        entitlements: initialEntitlements.map((entitlement) => ({
          productCode: entitlement.productCode,
          name: entitlement.name,
          annualPremium: 0,
          monthlyPremium: 0,
          price: 0,
          selected: false,
          loading: false,
        })),
      };
    }

    return member;
  });

  const setPriceToZero = (members: Member[]) => {
    dispatch(
      updateQuoteMonthlyPremiums({
        price: 0,
        tax: 0,
        totalContractPrice: 0,
      }),
    );
    members.forEach((member: Partial<Member>, index: number) => {
      const entitlements = member.entitlements?.map((entitlement) => ({
        ...entitlement,
        loading: false,
      }));

      dispatch(
        updateMember({
          userEditIndex: index,
          values: {
            monthlyPremium: member.monthlyPremium,
            annualPremium: member.annualPremium,
            entitlements,
          },
        }),
      );
    });
  };

  const isThereEntitlementSelectedForEveryone = (members: Member[]): boolean =>
    members.every((member) => member.entitlements.some((entitlement) => entitlement.selected));

  const hasAnyoneSelectedThisEntitlement = (members: Member[], productCode: string): boolean =>
    members.some((member) =>
      member.entitlements.some((entitlement) => entitlement.selected && entitlement.productCode === productCode),
    );

  useEffect(() => {
    dispatch(updateHelperIsProgressShown({ isProgressShown: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!loading) {
      setClickedCardIndex(undefined);
    }
  }, [loading]);

  useEffect(() => {
    async function price() {
      if (isQuoteUpdated(initialQuote, majorHealthQuestions, initialEntitlements, quoteSnapshot)) {
        if (isThereEntitlementSelectedForEveryone(initialQuote.members)) {
          getPrice({});
        } else {
          setPriceToZero(initialQuote.members);
        }
      }
    }
    price();
  }, []);

  function Submitter() {
    const { submitForm } = useFormikContext<{
      initialMembers: [];
      loading: boolean;
      quote: Quote;
    }>();

    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }

  useCustomEventListener('pageNavigationBack', () => {
    history(routes.majorHealthQuestions, navigateOptions);
  });

  const entitlementRulesMet = getEntitlementRulesMet({ productRules, initialMembers });
  const [rulesPushedToDataLayer, setRulesPushedToDataLayer] = useState([] as EntitlementErrorMessages[]);

  useEffect(() => {
    const uniqueRules = entitlementRulesMet.filter(
      (value, index, array) => array.findIndex((x) => x.message === value.message) === index,
    );
    const rulesToPush = uniqueRules.filter(
      (rule) => !rulesPushedToDataLayer.some((pushedRule) => pushedRule.message === rule.message),
    );
    if (rulesToPush) {
      const updatedPushedRules = [...rulesPushedToDataLayer, ...rulesToPush];
      const numberOfUniqueRules = updatedPushedRules.length;
      rulesToPush.forEach((rule) => {
        const tagManagerArgs = {
          dataLayer: {
            event: 'error',
            error_message: rule.message,
            error_location: rule.productCode ?? 'Top of the page',
            number_of_errors: numberOfUniqueRules,
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      });
      setRulesPushedToDataLayer(updatedPushedRules);
    }
  }, [entitlementRulesMet.length]);

  const getPageWarningMessage = (): string => {
    const pageWarningMessagePostfix = initialMembers.length > 1 ? ' for every member' : '';
    const entitlementErrorMessage = entitlementRulesMet.find((x) => x.error && x.productCode === undefined)?.message;
    let warningMessage = '';

    if (!isThereEntitlementSelectedForEveryone(initialMembers)) {
      const message = `You need to choose at least one care option${pageWarningMessagePostfix}.`;
      if (!rulesPushedToDataLayer.some((rule) => rule.message === message)) {
        const updatedPushedRules = [
          ...rulesPushedToDataLayer,
          { memberIndex: 0, message, error: true, mhqExclusionType: '' },
        ];
        setRulesPushedToDataLayer(updatedPushedRules);
        const tagManagerArgs = {
          dataLayer: {
            event: 'error',
            error_message: message,
            error_location: 'Top of the page',
            number_of_errors: updatedPushedRules.length,
          },
        };
        TagManager.dataLayer(tagManagerArgs);
      }
      warningMessage = message;
    } else if (entitlementErrorMessage) {
      warningMessage = entitlementErrorMessage;
    }

    setCloseError(false);

    return warningMessage;
  };

  const getNewMemberValuesPriceCard = (
    valuesInitialMembers: Member[],
    clickableItemProductCode: string,
    priceCardSelected: boolean,
  ) => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'entitlement_interaction',
        entitlement_product: '',
        entitlement_category: 'core product',
        entitlement_interaction_type: '',
        entitlement_member: '',
      },
    };
    let memberIndexes = '';
    const newValues = valuesInitialMembers.map((member: Member, memberIndex: number) => ({
      ...member,
      entitlements: member.entitlements.map((entitlement) => {
        if (isProductCodeMatchingClickedItem(entitlement, clickableItemProductCode)) {
          const isSelected = !!(member.policyHolder && !priceCardSelected);

          const updatedEntitlement = updateEntitlement(entitlement, priceCardSelected, isSelected, member);

          if (updatedEntitlement.selected !== entitlement.selected) {
            tagManagerArgs.dataLayer.entitlement_product = entitlement.name;
            tagManagerArgs.dataLayer.entitlement_interaction_type = priceCardSelected ? 'remove' : 'add';
            memberIndexes += `${memberIndex.toString()},`;
          }

          return updatedEntitlement;
        }

        return entitlement;
      }),
    }));

    tagManagerArgs.dataLayer.entitlement_member = memberIndexes.slice(0, memberIndexes.length - 1);
    TagManager.dataLayer(tagManagerArgs);

    return newValues;
  };

  const noConsultationOptionsForEveryMember = initialMembers.every(
    (member) => member.consultationConfig?.options?.length === 0,
  );

  const priceCardClickHandlerGetPrice = (
    members: Member[],
    cardIndex: number,
    cardSelected: boolean,
    productCode: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<{
      initialMembers: Member[];
      loading: boolean;
      quote: Quote;
    }>>,
  ) => {
    setClickedCardIndex(cardIndex);

    if (members.length > 1 && cardSelected) {
      setOpenedCardIndexes([...openedCardIndexes.filter((openedCardIndex) => openedCardIndex !== cardIndex)]);
    }

    const newMembersValues = getNewMemberValuesPriceCard(members, productCode, cardSelected);

    setFieldValue('initialMembers', newMembersValues);

    if (isThereEntitlementSelectedForEveryone(newMembersValues)) {
      getPrice({ newMemberValues: newMembersValues });
    } else {
      setPriceToZero(newMembersValues);
      setClickedCardIndex(undefined);
    }
  };

  const cardListItemClickHandler = (
    members: Member[],
    clickedMemberIndex: number,
    productCode: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<{
      initialMembers: Member[];
      loading: boolean;
      quote: Quote;
    }>>,
  ) => {
    const newMembersValues = getNewMemberValuesCardListItem(members, productCode, clickedMemberIndex);

    setFieldValue('initialMembers', newMembersValues);

    if (isThereEntitlementSelectedForEveryone(newMembersValues)) {
      getPrice({ newMemberValues: newMembersValues });
    } else {
      setPriceToZero(newMembersValues);
      setClickedCardIndex(undefined);
    }
  };

  return (
    <>
      <Header />
      <Main maxWidth="lg">
        <PageWrapper>
          <PageIntroductionWrapper role="main" aria-label="container">
            <PageIntroduction title="Choose the options you need" titleTestId="title" includeWrapper={false} />
          </PageIntroductionWrapper>
          <Formik
            enableReinitialize
            initialValues={{
              initialMembers,
              loading: false,
              quote: initialQuote,
            }}
            validateOnMount
            onSubmit={() => {
              if (isThereEntitlementSelectedForEveryone(initialMembers) && !entitlementRulesMet.some((x) => x.error)) {
                if (configData.FEATURES.RHL === 'true') {
                  history(routes.guidedSelection, navigateOptions);
                } else if (noConsultationOptionsForEveryMember) {
                  history(routes.excess, navigateOptions);
                } else {
                  history(routes.consultations, navigateOptions);
                }
              } else {
                setCloseErrorMessage(getPageWarningMessage());
                let counter = 0;
                const timer = setInterval(() => {
                  if (scrollToFirstError('.error-box') || counter > 9) {
                    clearInterval(timer);
                  }
                  counter += 1;
                }, 50);
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {closeErrorMessage && !closeError && (
                  <PageWarningContainer>
                    <ErrorMessageBox closeError={setCloseError} message={closeErrorMessage} />
                  </PageWarningContainer>
                )}
                {initialEntitlements.map((clickableItem, clickableItemIndex) => {
                  const hasAnyMemberSelectedEntitlement = hasAnyoneSelectedThisEntitlement(
                    values.initialMembers,
                    clickableItem.productCode,
                  );

                  const hasLeadSelectedEntitlement = values.initialMembers[0].entitlements.some(
                    (entitlement) => entitlement.productCode === clickableItem.productCode && entitlement.selected,
                  );
                  const priceCardSelected = hasLeadSelectedEntitlement || hasAnyMemberSelectedEntitlement;

                  const showNoticeOrWarningSingleMemberJourney = entitlementRulesMet.some(
                    (x) => x.productCode === clickableItem.productCode,
                  );

                  const priceCardClickHandlerShowMembers = () => {
                    const scrollToMembersList = setInterval(() => {
                      const entitlementMembersList = document.querySelector(
                        `[data-testid="whats-not-included-${clickableItem.name.replace(/\s+/g, '-').toLowerCase()}"]`,
                      );
                      entitlementMembersList?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                      clearInterval(scrollToMembersList);
                    }, 50);
                    setOpenedCardIndexes([...openedCardIndexes, clickableItemIndex]);
                  };

                  const content = entitlementContent.find((x) => x.productCode === clickableItem.productCode);

                  if (content === undefined) {
                    return null;
                  }

                  return (
                    <PriceCard
                      cardData={{
                        ...clickableItem,
                        description: content.description,
                        included: content.included,
                        excluded: content.excluded,
                        selected: priceCardSelected,
                        displayHandBookText: true,
                        isButtonHidden: !!(
                          values.initialMembers.length === 1 &&
                          !priceCardSelected &&
                          entitlementRulesMet.some(
                            (x) =>
                              x.mhqExclusionType === ProductRulesMhqExclusionTypes.noCover &&
                              x.productCode === clickableItem.productCode,
                          )
                        ),
                        showChildren:
                          priceCardSelected ||
                          !!(values.initialMembers.length > 1 && openedCardIndexes.includes(clickableItemIndex)) ||
                          showNoticeOrWarningSingleMemberJourney,
                      }}
                      loading={clickedCardIndex === clickableItemIndex}
                      key={clickableItem.name}
                      clickHandler={
                        values.initialMembers.length > 1 && !priceCardSelected
                          ? priceCardClickHandlerShowMembers
                          : () =>
                              priceCardClickHandlerGetPrice(
                                values.initialMembers,
                                clickableItemIndex,
                                priceCardSelected,
                                clickableItem.productCode,
                                setFieldValue,
                              )
                      }
                      dataTestId={clickableItem.productCode}
                    >
                      {values.initialMembers.length > 1 ? (
                        <MultiMemberCardListItems
                          productCode={clickableItem.productCode}
                          initialMembers={values.initialMembers}
                          entitlementRulesMet={entitlementRulesMet}
                          cardClickHandler={cardListItemClickHandler}
                        />
                      ) : (
                        <MemberNoticeAndWarnings
                          entitlementRulesMet={entitlementRulesMet}
                          productCode={clickableItem.productCode}
                        />
                      )}
                    </PriceCard>
                  );
                })}
                <Submitter />
              </Form>
            )}
          </Formik>
          <EverydayHealthcareBenefits />
          <EntitlementsInfoModal variant={infoModalVariant} openModal={openInfoModal} setOpenModal={setOpenInfoModal} />
          <Box sx={{ mt: { xs: 2, md: 4 } }}>
            <FlexYourOptions />
          </Box>
          <PageNavigation isNextButtonDisabled={loading} />
        </PageWrapper>
        <Footer isPrice priceLoading={loading} />
      </Main>
    </>
  );
}
