import { Box, Grid } from '@mui/material';
import { Formik, Form, useFormikContext } from 'formik';
import { ContactCard, colors, Text, Modal, H4 } from '@digitalportal-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AddMemberSchema, getEditMemberSchema } from '../../../validationSchema';
import DateFormField from '../DateFormField/DateFormField';
import { FormHolder, TextInputHolder, DropDownInputHolder } from './styles';
import TextInputField from '../../molecules/TextInputField/TextInputField';
import { Checkbox } from '../../atoms';
import { TitlesList } from '../../../enums/nameTitles';
import { Member } from '../../../redux/slices/quote/quote.types';
import AutoCompleteAddress from '../AutoCompleteAddress/AutoCompleteAddress';
import MemberValidation from '../../../modules/validation/memberValidation.json';
import { ReactComponent as Phone } from '../../../assets/svg/phone.svg';
import { TStore } from '../../../redux/store';
import { makePhoneCall } from '../../../lib/utils/phoneCall';
import { MessageBoxStyled } from '../../templates/styles';

export type EditMemberModalProps = {
  modalOpen: boolean;
  closeModal: () => void;
  initialValues: Member;
  policyHolderValues: Member;
  handleSubmit: (values: Member) => void;
  coverStartDate: string;
};
const EditMemberModal = ({
  modalOpen,
  closeModal,
  initialValues,
  policyHolderValues,
  handleSubmit,
  coverStartDate,
}: EditMemberModalProps): React.JSX.Element => {
  const [addressFieldsVisible, setAddressFieldsVisible] = useState(false);
  const phoneNumber = useSelector((state: TStore) => state.helpersState.helpers.salesPhoneNumber);
  function Submitter() {
    const {
      isValid,
      values: { addressSameAsPolicyHolder },
      validateForm,
      submitCount,
    } = useFormikContext<Partial<Member>>();
    useEffect(() => {
      if (!isValid && submitCount === 1) {
        setAddressFieldsVisible(true);
      }
    }, [isValid, submitCount]);

    useEffect(() => {
      validateForm();
    }, [addressSameAsPolicyHolder, validateForm]);

    return null;
  }
  const handleOnSave = (
    status: string | undefined,
    submitForm: (() => Promise<void>) & (() => Promise<string>),
    setStatus: (status?: string) => void,
  ) => {
    if (status === 'valid' || status === undefined) {
      submitForm();
    } else {
      setStatus('error');
    }
  };
  const { availability } = useSelector((state: TStore) => state.helpersState.helpers);
  return (
    <Formik
      validateOnChange
      initialValues={initialValues}
      validateOnMount
      enableReinitialize
      onSubmit={(values) => {
        handleSubmit(values);
      }}
      validationSchema={!initialValues.policyHolder ? AddMemberSchema : getEditMemberSchema(coverStartDate)}
    >
      {({ values, setFieldValue, submitForm, status, setStatus, errors }) => (
        <Form>
          <Modal
            title={
              !initialValues.firstname && !initialValues.lastname
                ? 'Who would you like to add?'
                : "Edit member's details"
            }
            variant="form"
            open={modalOpen}
            onClose={closeModal}
            onSave={() => handleOnSave(status, submitForm, setStatus)}
            saveButtonEnabled
            dataTestid={!initialValues.policyHolder ? 'modal-add-members' : 'modal-edit-policy-holder'}
          >
            <FormHolder display="flex" flexDirection="column" alignItems="center">
              <Grid container justifyContent="space-between">
                <Grid item width={1}>
                  <DropDownInputHolder>
                    <TextInputField
                      placeholder="Select"
                      name="title"
                      label="Title (optional)"
                      list={TitlesList}
                      testId="editMemberModal-title"
                    />
                  </DropDownInputHolder>
                </Grid>
                <TextInputHolder fullWidth>
                  <TextInputField label="First name" name="firstname" testId="editMemberModal-firstname" />
                </TextInputHolder>
                <TextInputHolder fullWidth>
                  <TextInputField label="Last name" name="lastname" testId="editMemberModal-lastname" />
                </TextInputHolder>
                {initialValues.policyHolder && (
                  <TextInputHolder fullWidth>
                    <TextInputField
                      label="What would you like us to call you?"
                      sublabel="For example, if you’re Elizabeth and want us to call you Liz, write Liz here."
                      name="preferredName"
                      testId="editMemberModal-preferredName"
                    />
                  </TextInputHolder>
                )}
                <DateFormField inputFieldName="dob" testId="dob" />
                {errors.dob === MemberValidation.validation.dob.maxAge.customMessage && (
                  <Box width="100%" sx={{ mb: { xs: '16px', md: '24px' } }}>
                    <ContactCard
                      icon={<Phone />}
                      header="I’m sorry, we can only add members under 75 online."
                      buttonText={`Call ${phoneNumber}`}
                      title="Call us"
                      bgColor={colors.lightBlue}
                      availabilityStatus={availability.workTimeText}
                      avatarBgColor={availability.availabilityStatus ? colors.white : colors.mercury}
                      buttonWidthFitContent
                      isButtonDisabled={!availability.availabilityStatus}
                      onButtonClick={() => makePhoneCall(phoneNumber)}
                      testId="EditMemberDOBContactCard"
                    />
                  </Box>
                )}
                {!initialValues.policyHolder && (
                  <>
                    <Box width="100%" mb="20px">
                      <Checkbox
                        name="addressSameAsPolicyHolder"
                        testId="addressSameAsPolicyHolder"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.checked) {
                            setFieldValue('fullAddress', policyHolderValues.fullAddress);
                            setFieldValue('addressLine1', policyHolderValues.addressLine1);
                            setFieldValue('addressLine2', policyHolderValues.addressLine2);
                            setFieldValue('city', policyHolderValues.city);
                            setFieldValue('postcode', policyHolderValues.postcode);
                            return;
                          }
                          setFieldValue('fullAddress', initialValues.fullAddress);
                          setFieldValue('addressLine1', initialValues.addressLine1);
                          setFieldValue('addressLine2', initialValues.addressLine2);
                          setFieldValue('city', initialValues.city);
                          setFieldValue('postcode', initialValues.postcode);
                        }}
                      >
                        <Text>We live at the same address</Text>
                      </Checkbox>
                    </Box>
                    {values.addressSameAsPolicyHolder && (
                      <Box width="100%">
                        <Text display="block" mb="10px">
                          {values.addressLine1}
                        </Text>
                        <Text display="block" mb="10px">
                          {values.addressLine2}
                        </Text>
                        <Text display="block" mb="10px">
                          {values.city}
                        </Text>
                        <Text display="block">{values.postcode}</Text>
                      </Box>
                    )}
                  </>
                )}
                {(!values.addressSameAsPolicyHolder || initialValues.policyHolder) && (
                  <AutoCompleteAddress
                    name="autocomplete"
                    setFieldValue={setFieldValue}
                    values={{
                      fullAddress: values.fullAddress,
                      addressLine1: values.addressLine1,
                      addressLine2: values.addressLine2,
                      city: values.city,
                      postcode: values.postcode,
                    }}
                    addressFieldsVisible={addressFieldsVisible}
                  />
                )}
                {!initialValues.policyHolder && (
                  <Box mt="24px" width="100%">
                    <MessageBoxStyled>
                      <H4 bold mb="8px">
                        Getting in touch with your family members
                      </H4>
                      <Text mb="8px">If this family member is over 16, please enter their email address.</Text>
                      <Text>
                        We’ll use this to contact them about setting up an online account, or with information about
                        their plan.
                      </Text>
                    </MessageBoxStyled>
                  </Box>
                )}
                <Box width="100%">
                  <TextInputField
                    label={`Email address ${!initialValues.policyHolder ? '(optional)' : ''}`}
                    name="email"
                    testId="editMemberModal-email"
                    inputmode="email"
                  />
                </Box>
                {initialValues.policyHolder && (
                  <Box width="100%">
                    <TextInputField
                      maxLength={24}
                      label="Phone number"
                      name="phone"
                      wrapperMarginDisabled
                      testId="editMemberModal-phone"
                      inputmode="tel"
                    />
                  </Box>
                )}
              </Grid>
              <Submitter />
            </FormHolder>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
export default EditMemberModal;
