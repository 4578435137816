import styled from 'styled-components';
import { Typography, Box } from '@mui/material';
import { theme, colors, H3, Text } from '@digitalportal-ui/core';
import { Main } from '../../templates/styles';
import { ReactComponent as CalendarIcon } from '../../../assets/svg/calendar_icon.svg';
import { ReactComponent as EmailIcon } from '../../../assets/svg/email_icon.svg';

export const PageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px;
  max-width: 1110px;

  > *:not(:last-child) {
    margin-bottom: 32px;
  }

  ${theme.breakpoints.down('xl')} {
    max-width: 930px;
  }

  ${theme.breakpoints.down('lg')} {
    max-width: 690px;
  }


  ${theme.breakpoints.down('md')} {
    width: 100%;
    > * {
      margin-bottom: 16px;
    }
  }
`;

export const StyledMain = styled(Main)`
  margin-top: 32px;
  margin-bottom: 32px;

  ${theme.breakpoints.down('md')} {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const HeroWrapper = styled(Box)`
  width: 100%;
  max-width: 1110px;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  text-align: left;
  justify-content: space-between;
  color: ${colors.white};
  align-items: center;

  ${theme.breakpoints.down('xl')} {
    max-width: 930px;
  }

  ${theme.breakpoints.down('lg')} {
    max-width: 690px;
  }

  ${theme.breakpoints.down('md')} {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: column;
    max-width: 100%;
  }
`;

export const QuoteTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  ${theme.breakpoints.down('md')} {
    margin-bottom: 16px;
  }
`;

export const StyledReminderTypography = styled(H3)`
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }
`;

export const DateTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }
`;

export const EmailIconStyled = styled(EmailIcon)`
  ${theme.breakpoints.down('md')} {
    width: 17px;
  }
`;

export const CalendarTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
  p {
    color: ${colors.white};
  }
`;

export const CalendarIconStyled = styled(CalendarIcon)`
  ${theme.breakpoints.down('md')} {
    width: 17px;
  }
`;

export const PostalTextWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }

  p {
    color: ${colors.white};
  }

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const StyledTitleTypography = styled(Typography)`
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 8px;

  ${theme.breakpoints.up('md')} {
    max-width: 270px;
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 16px;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 460px;
  }

  ${theme.breakpoints.up('xl')} {
    max-width: 650px;
  }
`;

export const HeroTextWrapper = styled(Box)`
  max-width: 728px;
  padding: 40px 0;

  ${theme.breakpoints.only('md')} {
    h3 {
      width: 328px;
    }
  }

  ${theme.breakpoints.down('xl')} {
    max-width: 608px;
  }

  ${theme.breakpoints.down('lg')} {
    max-width: 295px;
    padding: 0;
  }

  ${theme.breakpoints.down('md')} {
    max-width: 100%;
  }
`;

export const CardWrapper = styled(Box)`
  ${theme.breakpoints.down('md')} {
    width: 100%;
    margin-top: 32px;
  }
`;

export const StyledParagraphTypography = styled(Typography)`
  margin-bottom: 8px;
  line-height: 25px;

  ${theme.breakpoints.up('md')} {
    margin-bottom: 16px;
  }
`;

export const CustomText = styled(Text)<{
  $isBulletPoint: boolean;
  $isNewLine: boolean;
}>`
  margin-bottom: ${({ $isNewLine }) => ($isNewLine ? '8px' : '0')};
  margin-left: ${({ $isBulletPoint }) => ($isBulletPoint ? '32px' : '0')};
  display: ${({ $isBulletPoint }) => ($isBulletPoint ? 'list-item' : 'block')};
`;

export const MessageBoxWrapper = styled(Box)`
  border-bottom: 1px solid ${colors.mercury};
  padding: 16px;

  ${theme.breakpoints.up('md')} {
    padding: 32px;
  }
`;

  export const AccordionWrapper = styled(Box)`
  margin-bottom: 16px;
  ${theme.breakpoints.up('md')} {
    margin-bottom: 24px;
  }
`;
