import React, { RefObject, useCallback, useRef } from 'react';
import { Box } from '@mui/material';
import { ErrorMessage } from 'formik';
import TextInputField from '../../molecules/TextInputField/TextInputField';
import { ErrorMessageText } from '../../molecules/TextInputField/styles';
import { FieldWrapper, DOBTitle } from './styles';

type DateFormFieldProps = {
  inputFieldName: string;
  testId: string;
};

type InputMaskType = {
  day: RegExp;
  month: RegExp;
  year: RegExp;
};

export const inputMasks: InputMaskType = {
  day: /^(0?\d|1\d|2\d|30|31)$/,
  month: /^(0?\d|1[0-2])$/,
  year: /^(?:\d|\d{2}|\d{3}|\d{4})$/,
};

export default function DateFormField({ inputFieldName, testId }: DateFormFieldProps): React.JSX.Element {
  // workaround for DOB to switch between inputs without using TAB button
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputs: RefObject<HTMLInputElement>[] = [useRef(null), useRef(null), useRef(null)];

  const onFocus = useCallback(
    ({ nativeEvent: { target } }: React.FocusEvent) => {
      const listener = (event: Event) => {
        // eslint-disable-next-line no-shadow
        const { name, value, maxLength } = event.target as HTMLInputElement;
        if (value.length === maxLength) {
          setTimeout(() => {
            const elementIndex = inputs.findIndex(({ current }) => current?.name === name);
            const nextElement = inputs[elementIndex + 1];
            if (nextElement?.current) {
              nextElement.current.focus();
            }
            if (value.length === maxLength && target) {
              target.removeEventListener('input', listener);
            }
          });
        }
      };
      if (target) {
        target.addEventListener('input', listener);
      }
    },
    [inputs],
  );

  return (
    <Box display="flex" flexDirection="column">
      <DOBTitle align="left" data-testid="dob-title">
        Date of birth (DD/MM/YYYY)
      </DOBTitle>
      <Box display="flex" justifyContent="space-between">
        <FieldWrapper $day>
          <TextInputField
            ref={inputs[0]}
            onFocus={onFocus}
            maxLength={2}
            placeholder="DD"
            name={`${inputFieldName}.day`}
            testId="textInputField-day"
            mask={inputMasks.day}
            inputmode="numeric"
          />
        </FieldWrapper>
        <FieldWrapper $month>
          <TextInputField
            ref={inputs[1]}
            onFocus={onFocus}
            maxLength={2}
            placeholder="MM"
            name={`${inputFieldName}.month`}
            testId="textInputField-month"
            mask={inputMasks.month}
            inputmode="numeric"
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextInputField
            ref={inputs[2]}
            maxLength={4}
            placeholder="YYYY"
            name={`${inputFieldName}.year`}
            testId="textInputField-year"
            mask={inputMasks.year}
            inputmode="numeric"
          />
        </FieldWrapper>
      </Box>
      <ErrorMessage
        name={inputFieldName}
        render={(message) => {
          if (typeof message === 'string') {
            return (
              <ErrorMessageText mt={-2} mb={3} textAlign="center" width="100%" data-testid={`error-message-${testId}`}>
                {message}
              </ErrorMessageText>
            );
          }
          return null;
        }}
      />
    </Box>
  );
}
