import { Box } from '@mui/material';
import { Text, colors } from '@digitalportal-ui/core';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReactComponent as ElderlyAtPcImage } from '../../../assets/svg/elderly_at_pc.svg';
import { EveryDayHealthCareWrapper } from './styles';
import { StyledLink } from '../Link/Link';
import { updateModalInfo } from '../../../redux/slices/helpers';
import EntitlementContentModal from '../../molecules/EntitlementContentModal/EntitlementContentModal';
import { EntitlementContent } from '../../../redux/slices/helpers/helpers.types';
import ListWithChecks from '../ListWithChecks/ListWithChecks';

export type CardBodyProps = {
  includedPoints: EntitlementContent[];
  excludedPoints: EntitlementContent[];
  subtitle: string;
  title: string;
  productCode: string;
  showTitles?: boolean;
  chipText?: string;
  subHeaderText?: string;
  displayHandBookText?: boolean;
  disableModalLink?: boolean;
};

function CardBody({
  includedPoints,
  excludedPoints,
  subtitle,
  title,
  displayHandBookText,
  productCode,
  showTitles = true,
  chipText = 'Included',
  subHeaderText = '',
  disableModalLink,
}: CardBodyProps): React.JSX.Element {
  const [diagnosisModal, setDiagnosisModal] = useState(false);
  const dispatch = useDispatch();
  const includedPointsToShow = includedPoints.filter((x) => x.show);
  const includedPointDescriptions: string[] = [];
  const excludedPointsToShow = excludedPoints.filter((x) => x.show);
  const excludedPointDescriptions: string[] = [];

  includedPointsToShow.forEach((point) => includedPointDescriptions.push(point.descriptionBullet ?? point.description));
  excludedPointsToShow.forEach((point) => excludedPointDescriptions.push(point.descriptionBullet ?? point.description));

  return (
    <Box aria-label="content" role="contentinfo" display="flex" justifyContent="space-between">
      <Box>
        <Text mb={2} color={colors.darkGrey}>
          {subtitle}
        </Text>
        {includedPointDescriptions.length > 0 && productCode === 'EveryDayHealthCare' && (
          <ListWithChecks points={includedPointDescriptions} pointsIconColor={colors.oceanBlue} />
        )}
        {!disableModalLink && (
          <StyledLink
            to="#"
            aria-label="find-out-more-button"
            $margin="24px 0 0 0"
            onClick={(e) => {
              e.preventDefault();
              dispatch(updateModalInfo({ isModalOpen: true, modalTitle: title }));
              setDiagnosisModal(true);
            }}
            data-testid={`view-more-details-${title.replace(/\s+/g, '-').toLowerCase()}`}
          >
            View more details
          </StyledLink>
        )}
      </Box>
      <EntitlementContentModal
        title={title}
        includedPoints={includedPoints}
        excludedPoints={excludedPoints}
        openModal={diagnosisModal}
        setOpenModal={setDiagnosisModal}
        showTitles={showTitles}
        subHeaderText={subHeaderText}
        chipText={chipText}
        displayHandBookText={displayHandBookText}
      />
      {productCode === 'EveryDayHealthCare' && (
        <EveryDayHealthCareWrapper>
          <ElderlyAtPcImage data-testid="everyday-healthcare-image" />
        </EveryDayHealthCareWrapper>
      )}
    </Box>
  );
}

export default CardBody;
