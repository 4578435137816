import styled from 'styled-components';
import { Paper, Box, Link } from '@mui/material';
import { theme, colors } from '@digitalportal-ui/core';
import { ReactComponent as Download } from '../../../assets/svg/download_icon.svg';

export const DownloadCardStyle = styled(Paper)`
  text-align: left;
  box-shadow: 0px 2px 2px 0px #33333326;
  width: calc(50% - 16px);
  word-break: break-word;
  padding: 32px;
  display: flex;

  ${theme.breakpoints.down('md')} {
    width: 100%;
    padding: 16px;
  }
`;

export const CardContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconHolder = styled(Box)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${theme.breakpoints.up('md')} {
    width: 64px;
    height: 64px;
  }
`;

export const LinkStyle = styled(Link)`
  color: ${colors.oceanBlue300};
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  ${theme.breakpoints.up('md')} {
    font-size: 16px;
  }
`;

export const DownloadIcon = styled(Download)`
  height: 20px;

  ${theme.breakpoints.up('md')} {
    margin-left: 5px;
    height: 24px;
  }
`;
