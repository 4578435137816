import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Text, H3 } from '@digitalportal-ui/core';
import routes from '../../../enums/routes';
import { TStore } from '../../../redux/store';
import { CoreNetworkCardContent } from './HospitalNetworkSelectionStyles';
import NetworkContent from '../../../content/networkContent.json';
import CoreCard from '../../molecules/CoreCard/CoreCard';
import { StyledLink } from '../../atoms/Link/Link';

const hospitalCardValues = {
  title: 'Choosing a specialist',
  bubbleTitle: 'Available to every member',
  modal: {
    title: 'Hospital network',
    description: `You will be able to receive treatment within 
    your hospital and consultant network. 
    There are 3 network you can choose from: Guided, Standard and Extended. 
    The speed or quality of care you receive is the same for each option. 
    With the more comprehensive networks,
    you may get more flexibility in choosing a hospital or a consultant`,
  },
  contentImage: false,
};

export default function HospitalNetworkSelection(): React.JSX.Element {
  const history = useNavigate();
  const { hospitalNetwork, members } = useSelector((state: TStore) => state.quoteState.quote);
  const { navigateOptions } = useSelector((state: TStore) => state.helpersState.helpers);
  const networkOption = NetworkContent.find((x) => x.networkValue === hospitalNetwork);

  return (
    <CoreCard
      title={hospitalCardValues.title}
      bubbleTitle={members.length > 1 ? hospitalCardValues.bubbleTitle : ''}
      contentImage={hospitalCardValues.contentImage}
      modalTitle={networkOption?.header}
      modalChildren={
        <Box>
          {networkOption?.body.map(
          (text: string, index: number) => (
            <Text
              key={text.concat(index.toString())}
              variant="body1"
              style={{ marginBottom: text.includes('\n') ? '8px' : '0' }}
            >
              {text}
            </Text>
          ),
        )}
        </Box>
      }
      testId="hospital-network-selection"
      cardBodyMobileMarginBottom="8px"
    >
      <CoreNetworkCardContent>
        <Fragment key={networkOption?.header}>
          <H3 bold sx={{ marginBottom: { sm: '8px', md: '16px' } }}>
            {networkOption?.header}
          </H3>
          <Text sx={{ marginBottom: { sm: '8px', md: '16px' } }}>{networkOption?.body[0]}</Text>
        </Fragment>
        <Box textAlign="left">
          <StyledLink
            to=" "
            aria-label="change-your-choice-button-choosing-a-specialist"
            data-testid="change-your-choice-button-choosing-a-specialist"
            $marginTop="0"
            onClick={(e) => {
              e.preventDefault();
              history(routes.hospitals, navigateOptions);
            }}
          >
            Change your choice
          </StyledLink>
        </Box>
      </CoreNetworkCardContent>
    </CoreCard>
  );
}
