import { Box } from '@mui/material';
import { Text } from '@digitalportal-ui/core';
import dayjs from 'dayjs';
import {
  FooterContainer,
  FooterContainerStaticLinks,
  StaticLinksContainer,
  StaticLinkWrapper,
  StaticLink,
  StaticText,
  AnimationWrapper,
} from './styles';
import PriceDisplay from '../PriceDisplay/PriceDisplay';
import * as animationData from '../../../assets/animationsjsons/calculator.json';

type FooterProps = {
  isPrice?: boolean;
  priceLoading?: boolean;
  localPrice?: number;
};

function Footer({ isPrice, priceLoading, localPrice }: FooterProps): React.JSX.Element {
  return (
    <FooterContainer data-testid="footer">
      {priceLoading ? (
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AnimationWrapper data-testid="calculator-animation" loop autoplay animationData={animationData} />
          <Text semibold>We&apos;re working on your price</Text>
        </Box>
      ) : (
        isPrice && <PriceDisplay data-testid="footer-price-display" price={localPrice} />
      )}
    </FooterContainer>
  );
}

export const FooterWithStaticLinks = (): React.JSX.Element => {
  const currentYear = dayjs().year();
  const links = [
    {
      name: 'Information about AXA Health',
      link: 'https://www.axahealth.co.uk/axahealthplan/aboutaxahealth/',
      id: '',
    },
    {
      name: 'Privacy Policy',
      link: 'https://www.axahealth.co.uk/privacy-policy/?_ga=2.97354271.1224245054.1677597016-428873107.1670921676',
      id: '',
    },
    {
      name: 'Accessibility',
      link: 'https://www.axahealth.co.uk/accessibility/',
      id: '',
    },
    {
      name: 'Modern Slavery Act',
      link: 'https://www.axahealth.co.uk/modern-slavery-act/',
      id: '',
    },
    {
      name: 'Cookie Policy',
      link: 'https://www.axahealth.co.uk/cookie-policy/?_ga=2.26709949.1224245054.1677597016-428873107.1670921676',
      id: 'footer-cookie-container-link',
    },
    {
      name: 'Legal Information',
      link: 'https://www.axahealth.co.uk/axahealthplan/legal',
      id: '',
    },
     {
      name: `©${currentYear} AXA All Rights Reserved`,
      link: '',
      id: '',
    },
  ];

  return (
    <Box mt="auto">
      <FooterContainerStaticLinks data-testid="footer">
        <StaticLinksContainer display="flex" justifyContent="center" flexWrap="wrap" data-testid="footer-container">
          {links.map((link) => (
            <StaticLinkWrapper key={link.name} id={link.id}>
              {link.link === '' ? (
                <StaticText>{link.name}</StaticText>
              ) : (
                <StaticLink href={link.link} target="_blank" rel="noreferrer">
                  {link.name}
                </StaticLink>
              )}
            </StaticLinkWrapper>
          ))}
        </StaticLinksContainer>
      </FooterContainerStaticLinks>
    </Box>
  );
};

export default Footer;
