import { useFormikContext, Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { colors, H4, Text } from '@digitalportal-ui/core';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import RadioBlock from '../../molecules/RadioBlock/RadioBlock';
import { useCustomEventListener } from '../../../lib/utils/eventHandler';
import Header from '../../templates/Header/Header';
import { Main } from '../../templates/styles';
import { RadioContainer, RadioWrapper, Wrapper } from './styles';
import PageIntroduction from '../../molecules/PageIntroduction/PageIntroduction';
import RadioVariants from '../../../enums/radioVariants';
import PageNavigation from '../../templates/PageNavigation/PageNavigation';
import { TStore } from '../../../redux/store';
import routes from '../../../enums/routes';
import Footer from '../../templates/Footer/Footer';
import { updateHelperGuidedSelection } from '../../../redux/slices/helpers/helpers.slice';
import { updateQuoteHospitalNetwork } from '../../../redux/slices/quote/quote.slice';
import NetworkCodes from '../../../enums/networks';
import GuidedSelectionOptions from '../../../enums/guidedSelection';
import { useGetPrice } from '../../../hooks/useGetPrice';
import { defaultNetworkSelection } from '../../../lib/utils/defaultNetworkSelection';

type Option = { value: number; title: string; subtitle?: string };

const guidedOPTIONS: Array<Option> = [
  {
    value: GuidedSelectionOptions.guided,
    title: 'I want AXA to choose a specialist for me',
    subtitle: 'This will reduce the price of your premium',
  },
  {
    value: GuidedSelectionOptions.nonGuided,
    title: 'Greater flexibility',
  },
];
export default function GuidedSelection(): React.JSX.Element {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { getPrice, loading } = useGetPrice();
  const helpers = useSelector((state: TStore) => state.helpersState.helpers);
  const { guidedSelection, providers } = useSelector((state: TStore) => state.helpersState.helpers);
  const { hospitalNetwork, hospitalNetworkConfig } = useSelector((state: TStore) => state.quoteState.quote);
  const [originalHospitalNetworkSelection] = useState(hospitalNetwork);
  const [originalGuidedSelection] = useState(guidedSelection);

  const { members } = useSelector((state: TStore) => state.quoteState.quote);

  const noConsultationOptionsForEveryMember = members.every(
    (member) => member.consultationConfig?.options?.length === 0,
  );

  const radioClickHandler = (value: GuidedSelectionOptions) => {
    let network;

    if (value === originalGuidedSelection) {
      network = originalHospitalNetworkSelection;
    } else if (value === GuidedSelectionOptions.guided) {
      network = defaultNetworkSelection(providers);
    } else {
      network = NetworkCodes.flexibleOption;
    }

    // Temporary fix for FoundationOption not currently being in the hospital network config
    if (network === NetworkCodes.foundationOption && !hospitalNetworkConfig.options.some(option => option.value === NetworkCodes.foundationOption)) {
      network = hospitalNetworkConfig.defaultValue;
    }

    dispatch(updateQuoteHospitalNetwork({ hospitalNetwork: network }));
    dispatch(updateHelperGuidedSelection(value));
    getPrice({ selectedHospitalNetwork: network });
  };

  useCustomEventListener('pageNavigationBack', () => {
      history(routes.entitlements, helpers.navigateOptions);
  });

  function Submitter() {
    const { submitForm } = useFormikContext<{ guidedSelection: number }>();

    useCustomEventListener('pageNavigationNext', () => {
      submitForm();
    });

    return null;
  }
  return (
    <>
      <Header />
      <Main>
        <Wrapper>
          <Formik
            initialValues={{ guidedSelection }}
            onSubmit={() => {
              if (noConsultationOptionsForEveryMember) {
                history(routes.hospitals, helpers.navigateOptions);
              } else {
                history(routes.consultations, helpers.navigateOptions);
              }
            }}
          >
            <Form>
              <PageIntroduction
                title="Do you want to choose your specialist or let us select one for you?"
                titleTestId="title"
              />
              <RadioContainer>
                {guidedOPTIONS.map(({ value, title, subtitle }, index) => (
                  <RadioWrapper key={value} $noGutter={index + 1 === guidedOPTIONS.length}>
                    <RadioBlock
                      value={value}
                      name="guidedSelection"
                      dataTestId={`radioButton-${value}`}
                      variant={RadioVariants.row}
                      checkedColor={colors.oceanBlue}
                      onClick={() => radioClickHandler(value)}
                    >
                      <H4 color={colors.darkGrey} bold>
                        {title}
                      </H4>
                      {subtitle && <Text color={colors.darkGrey}>{subtitle}</Text>}
                    </RadioBlock>
                  </RadioWrapper>
                ))}
                <PageNavigation isNextButtonDisabled={loading} />
              </RadioContainer>
              <Submitter />
            </Form>
          </Formik>
        </Wrapper>
        <Footer isPrice priceLoading={loading} />
      </Main>
    </>
  );
}
